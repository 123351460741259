<template>
  <div class="default-wrap">
    <div class="body-content-wrap main-apply-coupon-wrap">
      <div
        class="apply-coupon-banner-box"
        :style="{
          backgroundImage:
            'url(https://splatformnew.oss-cn-shanghai.aliyuncs.com/zhiguoguo/apply_coupon_banner.png)',
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }"
      ></div>

        <!-- 左侧选择内容 -->
      <div class="apply-coupon-box content-wrap clearfix">
        <div class="apply-category left">
          <el-radio-group
            v-model="buttonListVal"
            size="small"
            @change="buttonListChange"
          >
            <el-radio
              :label="item.id"
              border
              v-for="(item, index) in buttonList"
              :key="index"
              >{{ item.text }}</el-radio
            >
          </el-radio-group>
        </div>

        <!-- 右侧内容 -->
        <div class="apply-detail right">
            <!-- 右侧筛选按钮 -->
          <div class="apply-select" v-show="buttonList2Show">
            <el-radio-group
              v-model="buttonList2Val"
              size="small"
              @change="buttonListChange2"
            >
              <el-radio
                :label="item.id"
                border
                v-for="(item, index) in buttonList2"
                :key="index"
                >{{ item.text }}</el-radio
              >
            </el-radio-group>
          </div>

        <!-- 人才领券时提示 -->
          <div class="notice-txt">
            <el-alert
              title="领此类劵的企业，应未入选过“创业南京”、“创业新港”等市、区级高层次创业人才计划"
              type="warning"
              show-icon
              v-show="noticeShow"
            >
            </el-alert>
          </div>

          <!-- 优惠券 -->
          <div class="list-data" v-show="couponShow">
            <div class="coupon-price">
              <div class="discounts">
                <span class="min">￥</span>
                <span class="max">{{ money }}万</span>
              </div>
              <div class="full-reduction">部分商品可用</div>
              <div class="jag"></div>
            </div>
            <div class="coupon-info">
              <div class="info-title">
                <div class="tag">限商品券</div>
                <div class="title">仅限创新券类商品可使用</div>
              </div>
              <div class="date-get">
                <div class="date">一年有效期</div>
              </div>
            </div>
          </div>

          <!-- 表单内容 -->
          <div class="apply-form-content">
            <dynamic-forms-for-archive
              :itemsReady="formReady"
              :moduleId="moduleId"
              :isEdit="true"
              :modelId="currentOrder.id"
              ref="dynamic"
            ></dynamic-forms-for-archive>
          </div>

          <!-- 提交按钮 -->
          <div class="form-submit-box clearfix">
            <div class="left">
              <div
                class="agreement"
                @click="openAgreementDialog"
                style="font-size: 28rpx"
              >
                <el-checkbox v-model="agreement" :disabled="true"
                  >阅读并同意</el-checkbox
                >
                <span style="color: #5096f8" @click="openAgreementDialog">{{
                  agreementTitle
                }}</span>
              </div>
            </div>
            <div class="right">
              <!-- #ifdef MP-WEIXIN -->
              <div v-if="!userAuth">
                <el-button
                  class="bottom-btn"
                  :disabled="!agreementBtnEnable"
                  @click="loginTip"
                  type="primary"
                  >提交申领</el-button
                >
              </div>
              <!-- #endif -->
              <div v-if="userAuth">
                <el-button
                  class="bottom-btn"
                  :disabled="!agreementBtnEnable"
                  @click="doSubmit"
                  type="primary"
                  >提交申领</el-button
                >
              </div>
            </div>

            <el-dialog
              width="600px"
              class="agreement-dialog"
              :title="agreementTitle"
              :visible.sync="agreementDialogShow"
              :close-on-click-modal="false"
              append-to-body
            >
              <div class="agreement-dialog-cont">
                <div class="agreement-art">
                  <div v-html="article.content"></div>
                </div>
                <div class="agreement-btns">
                  <el-button
                    @click="doAgree"
                    type="primary"
                    class="agree-btn"
                    size="samll"
                    >同意本协议</el-button
                  >
                </div>
              </div>
            </el-dialog>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dynamicFormsForArchive from "@/components/DynamicFormsForArchive/index";

import ConfigUtil from "../../common/Config";
import UserService from "@/services/UserService";
import OrderService from "@/services/OrderService";
import ArticleService from "@/services/ArticleService";
import MarketingSceneService from "@/services/MarketingSceneService";
import uni from "@/services/UNIService";

export default {
  components: {
    dynamicFormsForArchive,
  },
  data() {
    return {
      dynamicForm: false,
      buttonListVal: 8,
      buttonList: [
        // {
        //   text: "大学生创业",
        //   id: 0,
        // },
        // {
        //   text: "人才企业",
        //   id: 1,
        // },
        // {
        //   text: "瞪羚/独角兽",
        //   id: 2,
        // },
        {
          text: "科小认定领券",
          id: 8,
        },
        // {
        //   text: "其他",
        //   id: 3,
        // },
      ],
      buttonList2Val: 4,
      buttonList2Show: true,

      buttonList2: [
        {
          text: "高校在校生",
          id: 4,
        },
        {
          text: "高校毕业生(5年内)",
          id: 5,
        },
        {
          text: "青年留学生",
          id: 6,
        },
      ],
      couponShow: true,
      formReady: 0,
      currentOrder: {},
      mode: "date",
      noticeShow: false,
      notice: [
        "领此类劵的企业，应未入选过“创业南京”、“创业新港”等市、区级高层次创业人才计划",
      ],
      SubmitButton: true,
      userAuth: false,
      moduleId: "group_10000000000000000",
      goodsType: 24000000000000000,
      article: {},
      agreementTitle: "",
      agreement: false,
      agreementDialogShow: false,
      agreementBtnEnable: false,
      thatBang: false,
      money: null,
      id: null,
      info: {},
      companyName: "",
      modelId: uni.getStorageSync("userId"),
    };
  },
  async created() {
    // this.userAuth=await this.checkUserAuth(),
    this.userAuth = true;
    // console.log("userAuth=");
    // console.log('header = ',userAuth);

    // this.initFormInfo("8");
    // 默认科小认定
    this.buttonListVal =8;
    this.buttonListChange();

    this.initAgreement();
  },
  methods: {
    loginTip() {
      this.$message({
        message: "请先登录",
        type: "warning",
      });
    },
    // 左侧按钮选择
    buttonListChange() {
        // 人才领券
      if (this.buttonListVal == 1) {
        this.initFormInfo("1");
        this.buttonList2Show = false;
        this.couponShow = true;
      } 
      // 独角兽
      else if (this.buttonListVal == 2) {
        this.initFormInfo("2");
        this.buttonList2Show = false;
        this.couponShow = true;
      } 
      // 其他
      else if (this.buttonListVal == 3) {
        this.initFormInfo("3");
        this.buttonList2Show = false;
        this.couponShow = false;
      } 
      // 科小认定
      else if (this.buttonListVal == 8) {
        this.initFormInfo("8");
        this.buttonList2Show = false;
        this.couponShow = true;
      }
      // 高校人才
      else {
        this.initFormInfo("4");
        this.buttonList2Val = 4;
        this.buttonList2Show = true;
        this.couponShow = true;
      }
    },

   // 高校二级选择
    buttonListChange2() {
         // 高校人才--普通高校
      if (this.buttonList2Val == 4) {
        this.initFormInfo("4");
        this.couponShow = true;
      }
       // 高校人才--毕业5年内高校
       else if (this.buttonList2Val == 5) {
        this.initFormInfo("5");
        this.couponShow = true;
      } 
       // 高校人才--青年留学生
      else if (this.buttonList2Val == 6) {
        this.initFormInfo("6");
        this.couponShow = true;
      }
    },

    // 初始渲染表单
    initFormInfo(typeId) {
        typeId=typeId+'';
      switch (typeId) {
        // 人才
        case "1":
          this.money = 10;
          this.moduleId = "group_1000000000000000";
          this.noticeShow = true;
          break;
        case "2": //独角兽 50万元5折券
          this.money = 50;
          this.moduleId = "group_7000000000000000";
          this.noticeShow = false;
          break;
        case "3": //其他
          this.money = 0;
          this.moduleId = "group_7100000000000000";
          this.noticeShow = false;
          break;
          // 高校--大学生
        case "4":
          this.money = 2;
          this.moduleId = "group_10000000000000000";
          this.noticeShow = false;
          break;
          // 高校-五年内毕业大学生
        case "5":
          this.money = 2;
          this.moduleId = "group_11000000000000000";
          this.noticeShow = false;
          break;
          // 高校--留学生
        case "6":
          this.money = 2;
          this.moduleId = "group_11000000000000000";
          this.noticeShow = false;
          break;
          // 科小服务券
        case "8": 
            this.money = 1
            this.moduleId = 'group_9900000000000000';
            this.noticeShow = false;
            break;
        default:
          this.money = 0;
          this.moduleId = "group_1000000000000000";
          this.noticeShow = false;
          break;
      }
    },
    // 服务协议
    initAgreement() {
      this.agreementTitle = "《服务协议》";
    },
    async openAgreementDialog() {
      this.agreementTitle = "《服务协议》";
      this.article = await ArticleService.doGet(
        "2c93f40877379792017743d36a492192"
      );
      this.agreementDialogShow = true;
    },
    agreementScrolltolower() {
      console.log("agreementScrolltolower...");
      this.agreementBtnEnable = true;
    },
    callPhone() {
      uni.makePhoneCall({
        phoneNumber: "13182987339",
      });
    },
    loginCallback(res) {
      this.logining = false;
      // uni.hideLoading();
      if (UserService.checkUserAuth()) {
        this.doSubmit();
      }
    },
    async bindGetUserInfo(e) {
      this.logining = true;
      uni.showLoading({
        title: "正在登录",
      });
      await this.getUserInfo(e, this.loginCallback);
    },
    doSubmit() {
      if (!UserService.checkUserAuth()) {
        this.$message({
          message: "请先登录",
          type: "warning",
        });
        return false;
      }

      let that = this;
      that.agreementBtnEnable = false;
      // console.log(that.agreementBtnEnable)
      that.$refs.dynamic.doAU(
        uni.getStorageSync("userId"),
        async function (res, companyName) {
          console.log("au result:", res);
          // return;
          if (res.data.status == 1) {
            var companyName = companyName || uni.getStorageSync("nickName");
            that.companyName = companyName;
            that.doAddCompanyCoupon(companyName);
            that.doUpdateContactsInfo();
            uni.setStorageSync("nickName", companyName);
          } else {
            that.agreementBtnEnable = true;
          }
        }
      );
    },
    // 表单验证
    combine(list) {
      var submit = true;
      var that = this;
      list.forEach(function (item, index) {
        if (item.dfkey == "isBoss") {
          console.log(item);
          item.valueList.forEach((e, i) => {
            if (e.id == item.json && e.text == "是") {
              // uni.showToast({
              //     title: "很抱歉，您不满足本次申领要求，请关注后续政策！",
              //     icon: 'none',
              //     mask: true,
              //     duration: 2000
              // });

              that.$message("很抱歉，您不满足本次申领要求，请关注后续政策！");
              submit = false;
            }
          });
        }
        if (item.dfkey == "group_13003000000000000_1609814840895") {
          console.log(item);
          item.valueList.forEach((e, i) => {
            if (e.id == item.json && e.text == "否") {
              // uni.showToast({
              //     title: "很抱歉，您不满足本次申领要求，请关注后续政策！",
              //     icon: 'none',
              //     mask: true,
              //     duration: 2000
              // });

              that.$message("很抱歉，您不满足本次申领要求，请关注后续政策！");
              submit = false;
            }
          });
        }
        if (item.dfkey == "graduationTime") {
          console.log(item);
          var isFiveYear =
            new Date().getTime() / 1000 - 157680000 < item[item.dfkey] / 1000
              ? true
              : false;
          if (!isFiveYear) {
            // uni.showToast({
            //     title: "已毕业超过5年!",
            //     icon: 'none',
            //     mask: true,
            //     duration: 3000
            // });

            this.$message("已毕业超过5年!");
            submit = false;
          }
        }
      });
      // if(submit){
      //     this.$refs.dynamic.doAUDynamicFormItemValueRelations(this.modelId,
      //         async function(res) {
      //             console.log("au result:", res);
      //             if (res.data.status == 1) {
      //                 this.doAddCompanyCoupon(this.companyName)
      //                 this.doUpdateContactsInfo()
      //                 uni.setStorageSync('nickName', this.companyName);
      //             } else {
      //                 this.agreementBtnEnable = true
      //             }
      //         });
      // }
    },
    async doUpdateContactsInfo() {
      var res = await UserService.doUpdateContactsInfo({
        id: uni.getStorageSync("userId"),
        nickName: this.companyName,
        companyName: this.moduleId, // 申领优惠券时，存储合同类型
      });
      if (res.status == 1) {
        // uni.switchTab({
        //     url: '../My/My'
        // })
        // console.log(res.status)

        if (this.moduleId == "group_7100000000000000") {
          this.$message.success(
            "当前为特殊通道申请,需联系管理员才可申请对应创新券券额,请耐心等待"
          );

          setTimeout(() => {
            this.$router.push({
              path: "/home",
            });
          }, 1500);
        } else {
          this.$message.success("提交成功");
          setTimeout(() => {
            this.$router.push({
              path: "/my/myCoupons",
            });
          }, 1500);
        }
      }
    },
    /**
     * 优惠券tab点击
     * @param {Number} type
     */
    onCouponTab(type) {},
    doAgree() {
      console.log("doAgree...");
      this.agreement = true;
      this.agreementDialogShow = false;
      this.agreementBtnEnable = true;
      console.log(this.agreementBtnEnable);
    },
    async doAddCompanyCoupon(targetValue) {
      if (this.id == 2) {
        var res = await MarketingSceneService.doAddCompanyCoupon(
          targetValue,
          1022,
          ConfigUtil.getBusinessTradingAreaId()
        );
      } else {
        await OrderService.doAdd(this.money * 1000000);
      }
    },
    /**
     * 去使用点击
     */
    onCouponUse() {
      uni.navigateTo({
        url: "/pages/SearchGoodsList/SearchGoodsList",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
$base: #1754bf;

.main-apply-coupon-wrap {
  min-width: 1100px;
  position: relative;
  width: 100%;
  margin: 68px 0 40px;

  .apply-coupon-banner-box {
    width: 100%;
    height: 430px;
    position: relative;
  }

  .apply-coupon-box {
    padding-top: 50px;

    .apply-category {
      width: 210px;
      margin-right: 15px;

      .el-radio-group {
        /deep/.el-radio__input {
          display: none;
        }

        .el-radio {
          display: block;
          margin-bottom: 20px;
          height: 40px;
          line-height: 40px;
          padding: 0 15px;
          width: 100%;
          text-align: center;
          border: none;
          border-radius: 5px;
        }

        .el-radio.is-checked {
          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
          /deep/.el-radio__label {
            font-weight: bold;
          }
        }

        .el-radio.is-bordered + .el-radio.is-bordered {
          margin-left: 0;
        }

        /deep/.el-radio__label {
          font-size: 20px;
          padding-left: 0;
        }
      }
    }

    .apply-detail {
      width: calc(100% - 230px);

      .apply-select {
        text-align: left;
        margin-bottom: 30px;
      }

      .notice-txt {
        margin-bottom: 20px;
      }

      .list-data {
        display: flex;
        align-items: center;
        width: 100%;
        height: 140px;
        margin: 0 0 30px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        .coupon-price {
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 200px;
          height: 100%;
          background-color: $base;
          .discounts {
            display: flex;
            justify-content: center;
            align-items: flex-end;
            height: 70px;
            width: 100%;
            .min {
              color: #ffffff;
              font-size: 32px;
              font-weight: bold;
            }
            .max {
              font-size: 48px;
              color: #ffffff;
              font-weight: bold;
            }
          }
          .full-reduction {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 40px;
            font-size: 20px;
            color: #ffffff;
          }
          .jag {
            position: absolute;
            top: 0;
            left: -6px;
            width: 6px;
            height: 100%;
            background-size: 10px 6px;
            background-color: #fff;
            background-position: 100% 35%;
            background-image: linear-gradient(
                -45deg,
                $base 25%,
                transparent 25%,
                transparent
              ),
              linear-gradient(-135deg, $base 25%, transparent 25%, transparent),
              linear-gradient(-45deg, transparent 75%, $base 75%),
              linear-gradient(-135deg, transparent 75%, $base 75%);
          }
        }
        .coupon-info {
          width: calc(100% - 200px);
          height: 90%;
          padding: 0 25px;
          .info-title {
            display: flex;
            width: 100%;
            height: 40px;
            .tag {
              margin-right: 10px;
              padding: 0 16px;
              color: #ffffff;
              background-color: $base;
              font-size: 20px;
              height: 40px;
              line-height: 40px;
              border-radius: 40px;
            }
            .title {
              height: 40px;
              line-height: 40px;
              font-size: 22px;
              color: #212121;
            }
          }
          .date-get {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 50px;
            margin: 10px 0 0;
            padding: 0 0 20px;
            border-bottom: 2px dashed #eeeeee;
            .date {
              display: flex;
              align-items: center;
              font-size: 20px;
              color: #555555;
            }
            .get {
              padding: 10px 20px;
              background-color: $base;
              color: #ffffff;
              font-size: 24px;
              border-radius: 100px;

              .use {
                background-color: transparent;
                border: 1px solid $base;
                color: $base;
              }
            }
          }
          .describe-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 40px;

            color: #555555;
            font-size: 24px;

            .more {
              transform: rotate(90deg);
            }
          }
        }
      }

      .apply-form-content {
        //   text-align: left;
        /deep/.el-form-item__label {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        //   background: #f5f5f5;
        text-align: left;
        }
      }

      .form-submit-box {
        margin: 40px 0 20px;

        .agreement {
          height: 40px;
          line-height: 40px;
        }

        .bottom-btn {
          width: 200px;
        }
      }
    }
  }
}
</style>


<style lang="scss">
.agreement-dialog {
  .agreement-art {
    margin-bottom: 40px;
  }

  .agree-btn {
    width: 100%;
    padding: 0 20px;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
  }
}
.form-title {
  height: 60px;
  line-height: 60px;
}
.avatar-uploader {
  text-align: left;

  .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 148px;
    height: 148px;
    line-height: 148px;
    text-align: center;
  }
  .avatar {
    width: 148px;
    height: 148px;
    display: block;
  }
}
.file-uploader {
  .el-upload-list {
    .el-upload-list__item-name {
      display: none;
    }
    .el-upload-list__item-status-label {
      display: none;
    }
    .el-progress {
      margin-top: -40px;
    }
  }
  .file-uplode-tit {
    color: #0f71ea;
  }

  .el-icon-upload {
    margin: 5px 0 0;
    color: #ccc;
  }
}
</style>