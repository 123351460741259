/* eslint-disable semi-spacing,space-before-function-paren,quotes */
import http from '../common/Request'
const TatisticsRankingService = {
  async doAdd(key,value,score) {
    var res = await http({
      url: "tatisticsRanking_doAdd.do",
      method: "POST",
      data: {
        key:key,
        value:value,
        score:score
      }
    })
    return res.data.data
  }
}
export default TatisticsRankingService