<template>
    <div class="default-wrap">
        
        <div class="body-content-wrap main-apply-coupon-wrap">

             <div class="apply-coupon-banner-box" :style="{  backgroundImage:'url(https://splatformnew.oss-cn-shanghai.aliyuncs.com/zhiguoguo/news_detail_banner.png)',backgroundPosition: 'center center',backgroundRepeat:'no-repeat',backgroundSize:'cover' }" >
                    
            </div>

            <div class="article-box">
                <h1 class="article-title">
                    {{article.title}}
                </h1>
                <div class="article-info clearfix">
                    <div class="article-author">
                        {{article.author}}
                    </div>
                    <div class="article-date right">
                        {{article.time}}
                    </div>
                </div>
                <div class="article-content">
                    <div v-html="formatRichText(article.content)"></div>
                </div>
            </div>

        </div>
    </div>
            
</template>

<script>
import uni from "@/services/UNIService"

export default{
    components:{
        
    },

    
    data(){
        return {
            article:{
                title:'',
                author:'',
                time:'',
                content:""
            }
        }
    },

    created(){
        if(this.$route.params.art){
            this.article=this.$route.params.art;
            console.log(this.$route.params.art);

            uni.setStorageSync('newsArt',JSON.stringify(this.article));

        }
    },
    mounted(){
        this.article=JSON.parse(uni.getStorageSync('newsArt'));
    },
    beforeDestroy(){
        uni.removeStorageSync('newsArt')
    },
    methods:{
        /**
         * 处理富文本里的图片宽度自适应
         * 1.去掉img标签里的style、width、height属性
         * 2.img标签添加style属性：max-width:100%;height:auto
         * 3.修改所有style里的width属性为max-width:100%
         * 4.去掉<br/>标签
         * @param html
         * @returns {void|string|*}
         */
        formatRichText(html) { //控制小程序中图片大小
            if (html) {
                let newContent = html.replace(/<img[^>]*>/gi, function(match, capture) {
                    match = match.replace(/style="[^"]+"/gi, '').replace(/style='[^']+'/gi, '');
                    match = match.replace(/width="[^"]+"/gi, '').replace(/width='[^']+'/gi, '');
                    match = match.replace(/height="[^"]+"/gi, '').replace(/height='[^']+'/gi, '');
                    return match;
                });
                newContent = newContent.replace(/style="[^"]+"/gi, function(match, capture) {
                    match = match.replace(/width:[^;]+;/gi, 'max-width:100%;').replace(/width:[^;]+;/gi, 'max-width:100%;');
                    return match;
                });

                newContent = newContent.replace(/\<img/gi,
                    '<img style="max-width:100%;height:auto;display:inline-block;margin:10rpx auto;"');
                return newContent;
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.main-apply-coupon-wrap{
    min-width: 1100px;
    position: relative;
    width: 100%;
    margin-top: 68px;

    .apply-coupon-banner-box{ 
        width:100%;
        height: 430px;
        position: relative;  
    }

    .article-box{
        margin-top: 40px;

        .article-title{
            font-size: 30px;
            color: #333;
            width:600px;
            line-height: 44px;
            margin: 0 auto;
            font-weight:600;
        }

        .article-info{
            
            height: 40px;
            line-height: 40px;
            width: 900px;
            margin: 15px auto 25px;
            font-size: 24px;
            color: #666;

            .article-author,.article-date{
                display: inline;
            }

            .article-date{
                font-size: 20px;
            }

        }

        .article-content{
            width: 900px;
            margin: 0 auto;
            font-size: 18px;
            color: #666;
            text-align: left;

            /deep/p{
                line-height: 28px;
                margin-bottom: 15px;
            }
        }
    }

}

</style>