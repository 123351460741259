var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"default-wrap"},[_c('div',{staticClass:"body-content-wrap main-coupon-wrap"},[_c('div',{staticClass:"coupon-banner-box",style:({
        backgroundImage:
          'url(https://splatformnew.oss-cn-shanghai.aliyuncs.com/zhiguoguo/coupon_banner.png)',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      })}),_c('div',{staticClass:"banner-card-box"},[_c('div',{staticClass:"banner-card-content"},[_c('div',{staticClass:"card-col col-left"},[_c('div',{staticClass:"tit"},[_vm._v(_vm._s(_vm.money.actual)+"万元")]),_c('div',{staticClass:"sub-tit"},[_vm._v("发劵金额 (元)")])]),_c('div',{staticClass:"card-col col-right"},[_c('div',{staticClass:"tit"},[_vm._v(_vm._s(_vm.money.expect)+"万元")]),_c('div',{staticClass:"sub-tit"},[_vm._v("兑付金额 (元)")])])])]),_c('div',{staticClass:"coupon-card-box"},_vm._l((_vm.couponCards),function(item,index){return _c('div',{key:index,staticClass:"card-item",on:{"click":function($event){return _vm.gotoRoute(index)}}},[_c('div',{staticClass:"card-img"},[_c('img',{attrs:{"src":item.imgUrl,"alt":"item.title"}})]),_c('div',{staticClass:"card-txt"},[_vm._v(" "+_vm._s(item.title)+" ")])])}),0),_c('div',{staticClass:"total-box",style:({
        backgroundImage:
          'url(https://splatformnew.oss-cn-shanghai.aliyuncs.com/zhiguoguo/recommend_bg.png)',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      })},[_c('div',{staticClass:"total-content"},_vm._l((_vm.totals),function(item,index){return _c('div',{key:index,staticClass:"total-item"},[_c('div',{staticClass:"total-detail"},[_c('div',{staticClass:"total-icon"},[_c('img',{attrs:{"src":item.imgUrl,"alt":"item.title"}})]),_c('div',{staticClass:"total-num"},[_vm._v(" "+_vm._s(item.count)+" ")])]),_c('div',{staticClass:"total-tit"},[_vm._v(" "+_vm._s(item.title)+" ")])])}),0)]),_c('div',{staticClass:"notice-box"},[_vm._m(0),_c('su-news-box',{attrs:{"title":"公示列表","categoryId":_vm.categoryId,"count":3}})],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"common-title"},[_c('div',{staticClass:"title-top"},[_vm._v("公示")]),_c('h3',[_vm._v("RELEASE")])])}]

export { render, staticRenderFns }