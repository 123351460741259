<template>
    <el-dialog 
    width="900px"
    :visible.sync="dialogConfirmOrderVisible"
    :close-on-click-modal="false"
    @close="close"
    class="confirm-order-diglog">
        <div slot="title" class="dialog-title">
            填写订单
        </div>
        <div class="confirm-order-content">
          
          <!-- 地址信表单 -->
          <div class="address-form-box">
			  <area-cascade :areaLevel="-1" :type="'area'" @exportArea="exportArea" :areaId="this.deliverAddress.areaId+''"></area-cascade>

              <el-form :model="deliverAddress" ref="addressForm" class="address-form" size="small" :rules="rules">
                <el-row>
                  <el-col :span="12">
                      <el-form-item label="姓名" :label-width="formLabelWidth" prop="receiveName">
                        <el-input v-model="deliverAddress.receiveName" autocomplete="off" placeholder="请输入姓名"></el-input>
                      </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="手机号码" :label-width="formLabelWidth" prop="mobile">
                        <el-input v-model="deliverAddress.mobile" autocomplete="off" placeholder="请输入手机号"></el-input>
                      </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-form-item label="收货地址" :label-width="formLabelWidth" prop="simpleAddress">
                    <el-input v-model="deliverAddress.detailAddress" autocomplete="off" placeholder="请输入收货地址"></el-input>
                  </el-form-item>
                </el-row>
            
              </el-form>
              <div class="order-process">
                (<span>提交需求</span> - <span>联系卖家</span> - <span>签订合同</span> - <span>卖家确认合同</span> - <span>用劵支付</span>)
              </div>
          </div>

          <!-- 订单信息 -->
          <div class="order-info-box">
            <div class="dialog-title">
                商品信息
            </div>
            <div class="goods-list-content">
                <div v-for="(goods, index) in goodss" :key="index" class="goods-item">
                    <su-goods-item  :goods="goods" :cartModel="true" :shareMoney="false" @cartChange="cartChange" v-if="goods.cartCount>0"></su-goods-item>
                </div>  
            </div>

            <div class="order-remark">
              <el-form :model="order" class="" size="samll">
                <el-form-item label="需求" :label-width="'45px'">
                  <el-input v-model="order.remark" autocomplete="off" type="textarea"
                  placeholder="选填建议先和商家沟通" :autosize="{ minRows: 3, maxRows: 3}">
                  </el-input>
                </el-form-item>
              </el-form>
            </div>

          </div>
        
        </div>
        
        <div slot="footer" class="dialog-footer">
            <div class="pay-info">
              <div class="total-price">
                支付：<span class="goods-amount">待卖家确认金额</span>
              </div>
              <div class="agreement" @click="openAgreementDialog" style="font-size: 28rpx">
                <el-checkbox v-model="agreement" :disabled="true">阅读并同意</el-checkbox>
                <span style="color: #5096F8" @click.stop="openAgreementDialog">{{agreementTitle}}</span>
              </div>
            </div>
            <el-button  class="order-sub-btn" type="primary"  @click="goPaying" size="small" :disabled="!agreement">提交需求</el-button>
        </div>

		<!-- 协议弹框 -->
        <el-dialog
          width="600px"
          class="agreement-dialog"
          :title="agreementTitle"
          :visible.sync="agreementDialogShow"
          :close-on-click-modal="false"

          append-to-body>
          <div class="agreement-dialog-cont">
            <div class="agreement-art">
              <div v-html="article.content"></div>
            </div>
            <div class="agreement-btns">
              <el-button @click="doAgree" type="primary" class="agree-btn">同意本协议</el-button>
            </div>
          </div>
        </el-dialog>

    </el-dialog>
</template>


<script>
import suGoodsItem from '@/components/su-goods-item/su-goods-item.vue'
import areaCascade from '@/components/AreaSelect/areaCascade.vue'


import ConfigUtil from '@/common/Config'
import CartService from '@/services/CartService'
import AddressService from '@/services/AddressService'
import OrderService from '@/services/OrderService'
import GroupService from '@/services/GroupService'
import InvoiceService from '@/services/InvoiceService'
import OrderFunction from "@/mixins/Order"
import MarketingSceneService from "@/services/MarketingSceneService";
import ArticleService from "@/services/ArticleService";
import uni from '@/services/UNIService'

	export default {
		components: {
			suGoodsItem,
			areaCascade
		},
		mixins: [OrderFunction],
		data() {
			return {
				dialogConfirmOrderVisible:false,
       
				formLabelWidth: '90px',
				rules: {
					receiveName: [
						{ required: true, message: '请输入姓名', trigger: 'blur' },
					],
					mobile: [
						{ required: true, message: '请输入手机号', trigger: 'blur' }
					],
					simpleAddress: [
						{ required: true, message: '请输入收货地址', trigger: 'blur' }
					],
				},


				config: {
					title: '确认订单',
				},
				senTypeTabList: [{
						sendType: 3,
						name: '上门取送车代办'
					},
					{
						sendType: 2,
						name: '自驾到店'
					}
				],
				senTypeTabCur: 0,
				article: {},
				agreementTitle: "",
				agreement: false,
				agreementDialogShow: false,
				agreementBtnEnable: false,
				goodss: [],
				userId: '',
				deliverAddress: {
					receiveName:'',
					mobile:'',
					simpleAddress:'',
					areaId: '',
					city: "",
					cityId: '',
					detailAddress: "",
					district: "",
					id: "",
					isDefault: null,
					orderSeq: 1,
					province: "",
					provinceId: '',
					type: 1,
					userId: ""
				},
				invoice: null, //发票
				notes: {
					isMust: false,
					placeholder: '选填，请先和商家协商一致',
					content: ''
				},
				order: {
					orderType: 1,
					sendType: 3,
					appId: "",
					bussinesstype: 0,
					receiveAddressId: '',
					payTypes: 0,
					totalPrice: 0,
					paymentAmount: 0,
					integralAmount: '-',
					count: 1,
					isImmediatePayment: 0,
					totalOriginalPrice: 0
				},
				disabled: true,
				authority: {
					address: null,
					invoiceTitle: null
				},
				servicesCategoryId: '',
				servicesGroupId: '',
				serviceName: '请选择安装门店',
				goodsType: 24000000000000000,
				bookingStatus: 0,
				bookingPreDay: 0,
				groupName: '',
				authenticationStatus: 0,
				locationAuthority: false,
				nearGroup: null,
				sgroupCount: 0,
				counter: 0,
				preferential: {
					totalPrice: 0,
					totalReducePrice: 0,
					couponReduce: 0,
					userCardReduce: 0,
					marketingSenceReduce: 0,
					scoreReduce: 0,
					realPrice: 0
				}
			}
		},
		props:{
			
		},
		watch: {
			deliverAddress(newValue) {
        		console.log(newValue)
				if (newValue) {
					this.order.receiveAddressId = newValue.id;
				}
			},
		},
		// events: {},
		// onShow() {
		// 	this.goodss = uni.getStorageSync("goodss_buy");
		// 	this.goodss[0].buyCount = this.goodss[0].cartCount
		// 	if (this.goodss && this.goodss.length > 0) {
		// 		this.goodsType = this.goodss[0].categoryId;
		// 		this.bookingStatus = this.goodss[0].bookingStatus;
		// 		this.bookingPreDay = this.goodss[0].bookingPreDay;
		// 	}

		// 	if (this.goodsType == 208004000000000000) {
		// 		this.senTypeTabList = [{
		// 			sendType: 2,
		// 			name: '自驾到店'
		// 		}];
		// 	} else if (this.goodsType == 208005000000000000) {
		// 		this.senTypeTabList = [{
		// 				sendType: 3,
		// 				name: '上门取送车代办'
		// 			},
		// 			{
		// 				sendType: 2,
		// 				name: '自驾到店'
		// 			}
		// 		]
		// 	}

		// 	// #ifdef MP-WEIXIN
		// 	var currPage = getCurrentPages()[getCurrentPages().length - 1]; //获取当前页面
		// 	if (currPage.data.deliverAddress) {
		// 		this.deliverAddress = currPage.data.deliverAddress;
		// 		this.order.receiveAddressId = this.deliverAddress.id;
		// 	}
		// 	// #endif
		// },
		async created() {
      
		},
		methods: {
			
			async add(options){
				this.dialogConfirmOrderVisible=true;
				this.goodss = JSON.parse(uni.getStorageSync("goodss_buy"));
				console.log(this.goodss);
				this.goodss[0].buyCount = this.goodss[0].cartCount
				if (this.goodss && this.goodss.length > 0) {
					this.goodsType = this.goodss[0].categoryId;
					this.bookingStatus = this.goodss[0].bookingStatus;
					this.bookingPreDay = this.goodss[0].bookingPreDay;
				}
				await this.checkUserAndGetSessionKey();
				
				// this.order.appId = ConfigUtil.getWXAAppId();
				this.groupName = options.groupName;
				this.order.groupId = options.groupId;
				this.sgroupCount = options.sgroupCount;
				this.authenticationStatus = +options.authenticationStatus;
				if (!this.groupName) {
					var group = await GroupService.doGetOneGroup(options.groupId);
					this.groupName = group.name;
					this.sgroupCount = group.sgroupCount;

				}
				// uni.showLoading({
				//   title: '正在加载'
				// });

				this.servicesCategoryId = ConfigUtil.getPath().indexOf('www') != -1 ? '16006002000000000' : '16010001000000000';
				this.userId = uni.getStorageSync("userId");
				var marketingScene = uni.getStorageSync("marketingScene_cache");
				if (marketingScene) {
					this.order.marketingScenes = [];
					this.order.marketingScenes.push({
					id: marketingScene.id
					});
					this.order.fromActivityId = marketingScene.id;
					this.order.sendType = marketingScene.sendType;
					if (this.order.sendType == 2 || this.order.sendType == 4) {
					this.doPreGetNearGroup();
					}
				} else {
					var categoryId = this.goodss[0].categoryId;
					if (categoryId == 21000000000000000 || categoryId == 207000000000000000) {
					//this.order.sendType = 4;
					}
				}
				// this.doGetAuthority();
				this.doGetDefaultAddress();
				// this.doPrePay();
				this.doPreGetNearGroup();
				this.initAgreement();
			},

			// 关闭订单弹框
			close(){
				this.dialogConfirmOrderVisible=false;
				this.senTypeTabCur=0;
				this.article= {};
				this.agreementTitle="";
				this.agreement= false;
				this.agreementDialogShow= false;
				this.agreementBtnEnable= false;
				this.goodss= [];
				this.userId= '';
				this.deliverAddress= {
					receiveName:'',
					mobile:'',
					simpleAddress:'',
					areaId: '',
					city: "",
					cityId: '',
					detailAddress: "",
					district: "",
					id: "",
					isDefault: null,
					mobile: "",
					orderSeq: 1,
					province: "",
					provinceId: '',
					type: 1,
					userId: ""
				};
				this.invoice= null; //发票
				this.notes= {
					isMust: false,
					placeholder: '选填，请先和商家协商一致',
					content: ''
				}
				this.order={
					orderType: 1,
					sendType: 3,
					appId: "",
					bussinesstype: 0,
					receiveAddressId: '',
					payTypes: 0,
					totalPrice: 0,
					paymentAmount: 0,
					integralAmount: '-',
					count: 1,
					isImmediatePayment: 0,
					totalOriginalPrice: 0
				}
				this.disabled= true;
				this.authority= {
					address: null,
					invoiceTitle: null
				}
				this.servicesCategoryId= '';
				this.servicesGroupId= '';
				this.serviceName= '请选择安装门店';
				this.goodsType= 24000000000000000;
				this.bookingStatus= 0;
				this.bookingPreDay= 0;
				this.groupName= '';
				this.authenticationStatus= 0;
				this.locationAuthority= false;
				this.nearGroup= null;
				this.sgroupCount= 0;
				this.counter= 0;
				this.preferential= {
					totalPrice: 0,
					totalReducePrice: 0,
					couponReduce: 0,
					userCardReduce: 0,
					marketingSenceReduce: 0,
					scoreReduce: 0,
					realPrice: 0
				}
		
			},

			exportArea(obj){
				// console.log("order addressForm",obj);
		
				this.deliverAddress={
					areaId: obj.areaId,
					city:  obj.cityName,
					cityId:  obj.cityId,
					detailAddress:  this.deliverAddress.detailAddress,
					district:  obj.areaName,
					id: this.deliverAddress.id,
					isDefault: this.deliverAddress.isDefault,
					mobile:  this.deliverAddress.mobile,
					orderSeq:  this.deliverAddress.orderSeq,
					province: obj.provinceName,
					provinceId: obj.provinceId,
					receiveName:this.deliverAddress.receiveName,
					simpleAddress: this.deliverAddress.simpleAddress,
					type: this.deliverAddress.type,
					userId: this.deliverAddress.userId
				}
			},

			senTypeTabSelect(index) {
				if (index === this.senTypeTabCur) {
					return false
				};
				this.senTypeTabCur = index;
				if (index == 1) {
					this.doPreGetNearGroup();
				}
			},
			async handlerOpenSetting(e) {
				let res = await this.openSetting(e);
				if (res) {
					this.doPreGetNearGroup();
				}
			},
			async doPreGetNearGroup() {
				console.log("doPreGetNearGroup...");
				var res = await this.getLocation();
				console.log(res);
				var that = this;
				if (this.latitude) {
					this.locationAuthority = true;
					this.doGetNearGroup();
				} else {
					this.locationAuthority = false;
				}
			},
			async doGetNearGroup() {
				var location = this.longitude + ',' + this.latitude;
				var searchModel = {};
				searchModel = {
					id: this.order.groupId,
					location: location,
					auditingStatus: 0
				};

				var nearRes = await GroupService.doPageNear(searchModel, 1, 1);
				if (nearRes.data && nearRes.data.length > 0) {
					this.nearGroup = nearRes.data[0];
				}
			},
			openLocation() {
				uni.openLocation({
					latitude: this.nearGroup.latitude,
					longitude: this.nearGroup.longitude,
					name: this.nearGroup.address,
					success: function() {
						console.log('success');
					}
				});
			},
			async makePhone() {
				uni.makePhoneCall({
					phoneNumber: this.nearGroup.contact
				});
			},
			async doGetAuthority() {
				// #ifdef MP-WEIXIN
				let res = await uni.getSetting();
				var settingRes = res[1]
				this.authority.address = settingRes.authSetting["scope.address"] ? settingRes.authSetting["scope.address"] : null;
				this.authority.invoiceTitle = settingRes.authSetting["scope.invoiceTitle"] ? settingRes.authSetting[
					"scope.invoiceTitle"] : null;
				// #endif

				// #ifdef APP-PLUS
				this.authority.address = true;
				this.authority.invoiceTitle = true;
				// #endif
			},
			async doAddressAuth() {
				console.log('开始认证地址。。。。。。');
				var that = this;

				// #ifdef MP-WEIXIN
				uni.authorize({
					scope: 'scope.address',
					success() {
						that.authority.address = true;
						that.doChooseAddress();
					},
					fail() {
						that.authority.address = false;
					}
				})
				// #endif

			},
			async donInvoiceTitleAuth() {
				console.log('开始认证发票。。。。。。');
				var that = this;
				uni.authorize({
					scope: 'scope.invoiceTitle',
					success() {
						that.authority.invoiceTitle = true;
					},
					fail() {
						that.authority.invoiceTitle = false;
					}
				})
			},
			handlerAddressSetting(e) {
				console.log('授权地址..........')
				this.authority.address = e.detail.authSetting["scope.address"];
				this.authority.invoiceTitle = e.detail.authSetting["scope.invoiceTitle"];
				if (this.authority.address) {
					this.doChooseAddress();
				}
			},
			handlerReceiptSetting(e) {
				console.log('授权发票..........');
				console.log(e);
				this.authority.address = e.detail.authSetting["scope.address"];
				this.authority.invoiceTitle = e.detail.authSetting["scope.invoiceTitle"];
				if (this.authority.invoiceTitle) {
					this.doWXChooseInvoiceTitle();
				}
			},
			bindblur(e) {
				this.notes.content = e.detail.value;
			},
			doCheckboxChange() { //协议同意状态的改变
				this.agreement = !this.agreement;
			},
			
			async goPaying() {

				if(!(this.deliverAddress.cityId && this.deliverAddress.provinceId && this.deliverAddress.areaId)){
					this.$message({
						showClose: true,
						message: '请选择省市区',
						type: 'warning'
					});
					return false;
				}
			
				// 表单验证
				this.$refs['addressForm'].validate((valid) => {
					if (valid) {
						
						this.disabled = false;
					} else {

						this.$message({
							showClose: true,
							message: '请填写地址信息',
							type: 'warning'
						});

						console.log('error submit!!');
						return;
					}
				});

        		if (this.disabled) {
					return;
				}

				this.order.sendType = this.senTypeTabList[this.senTypeTabCur].sendType;
				if (!this.agreement) {
					this.openAgreementDialog();
					this.disabled = false;
					return;
				}

				if (!this.userAuth) {
					// this.$refs["login"].open();
					this.$message({
						message: '请先登录',
						type: 'warning'
					});
					setTimeout(()=>{
						this.$router.push({
						path:'/login'
						})
					},1500)

					this.disabled = false;
					return;
				}

				//   if(!this.order.bookingDate) {
				//     uni.showToast({
				//       title: "请选择预约时间",
				//       icon: 'none',
				//       duration: 1500
				//     });
				//     this.disabled = false;
				//     return;
				//   }
				this.disabled = true;
				// var that = this;
				this.order.notes = this.notes.content;
				// console.log("this.order.sendType:" + this.order.sendType);
				// if (!this.order.receiveAddressId && this.order.sendType == 3) {
					// uni.showModal({
					// 	content: '请填写联系人信息！',
					// 	showCancel: false,
					// 	success() {
					// 		that.disabled = false;
					// 	}
					// })
					// this.$message({
					//   showClose: true,
					//   message: '请填写联系人信息！',
					//   type: 'warning'
					// });
					// this.disabled = false;
					// return;
				// }

				// 如果没有默认地址
				if(!this.order.receiveAddressId){
					
					// 获取不到默认地址先写死
					let addressParams={
							// userName:this.deliverAddress.receiveName,
							// detailInfo: this.deliverAddress.simpleAddress,
							// telNumber: this.deliverAddress.mobile,
							userName: this.deliverAddress.receiveName,
							provinceName: this.deliverAddress.province,
							cityName: this.deliverAddress.city,
							countyName:this.deliverAddress.district,
							// postalCode: '510000',
							detailInfo: this.deliverAddress.simpleAddress,
							telNumber: this.deliverAddress.mobile,
							type: 1,

					};

					let resAddress=await AddressService.doWXChooseAddress(addressParams);
					// console.log(resAddress)
					if(resAddress){
						this.order.receiveAddressId = resAddress.id;
					}else{
						this.$message({
							showClose: true,
							message: '地址信息错误',
							type: 'warning'
						});
					
						this.disabled = false;
						return;
					}
				}
				// 如果有默认地址
				else{
					let res= await AddressService.deliver_address_edit(this.deliverAddress);
					// console.log('地址更新',res)
				}

				if (this.notes.isMust && (!this.order.notes || this.order.notes == '')) {
					// uni.showModal({
					// 	content: '请填写备注信息！',
					// 	showCancel: false,
					// 	success() {
					// 		that.disabled = false;
					// 	}
					// })

					this.$message({
						showClose: true,
						message: '请填写备注信息！',
						type: 'warning'
					});
					this.disabled = false;
					return;
				}

				var cartIds = "";

				
				this.goodss.forEach(function(goods) {
					if (goods.cartCount > 0) {
						cartIds += goods.cartId + ",";
						goods.count=goods.cartCount;
					}
				});
		
				if (cartIds) {
					cartIds = cartIds.substr(0, cartIds.length - 1);
				}

				this.order.cartIds = cartIds;
				if (this.nearGroup) {
					this.order.servicesGroupId = this.nearGroup.id;
				}
				this.order.shareUserId = this.getCaptainUserId();
				// 1、添加订单
				var res = await OrderService.doAddgoods(this.order);
				if (res.status != 1) {
					// uni.showModal({
					// 	content: res.data + ',请重新下单',
					// 	showCancel: false,
					// 	success() {
					// 		uni.removeStorageSync("goodss_buy");
					// 		uni.removeStorageSync("marketingScene_cache");
					// 		that.disabled = false;
					// 		uni.navigateBack({
					// 			delta: 1
					// 		});
					// 	}
					// })

					this.$message({
						showClose: true,
						message: res.data + ',请重新下单',
						type: 'error'
					});
					uni.removeStorageSync("goodss_buy");
					uni.removeStorageSync("marketingScene_cache");
					this.disabled = false;
					this.closeOrder=false;

					return;
				}
				
				res.data.integralAmount = this.order.integralAmount;
				res.data.paymentAmount = this.order.paymentAmount;
				this.order = res.data;

				// 2、断商品分类是否为服务券产品，如果是直接把商家确认给静默做完--2022.5.20	
				if (res.data.items && res.data.items[0]['goodsCategoryId']=='212008001000000000') {
					// 服务券继续操作
					// 2.1、服务券订单-自动确认
					var confirmRes = await OrderService.doAutoConfirmOrder(res.data.id,'88e33dac4e6111eba3041fbe40e0a626');
					// console.log('confirmRes>>>>>',confirmRes);
					if(confirmRes.status==1){
						// 服务券订单预付款
						this.doPrePay()
					}
					
				} else{
					
					// 创新券订单提交成功;返回弹框提示
					this.$message({
						showClose: true,
						message: '订单提交成功',
						type: 'success'
					});
					// 关闭弹框
					this.close();
				}
				// this.doWxRequestPayment(this.order);
			},
			// 订单预付款  -- 2022.5.20 新增
			async doPrePay() { //获取下单需要支付的金额和扣除的积分金额
				// console.log("doPrePay......");
			
				var that = this;
					// console.log("doPrePay......",that.order.items)
				
				// that.order.items.forEach(function(item) {
				// 	var goods = {};
				// 	goods.tradingAreaId = ConfigUtil.getBusinessTradingAreaId()
				// 	goods.id = item.goodsId;
				// 	goods.cartCount = item.count;
				// 	goods.goodsPrice = item.goodsPrice;
				// 	goods.totalPrice = item.totalPrice;
				// 	goods.categoryId = item.goodsCategoryId;
				// 	goods.goodsShopCategoryId = item.goodsShopCategoryId;
				// 	goods.presentPrice = item.goodsPrice;
				// 	goods.discountStatus = item.discountStatus;
				// 	goods.groupId = that.order.groupId;
				// 	that.order.goodss.push(goods);
				// 	// goods.sendType = that.senTypeTabList[that.senTypeTabCur].sendType;
				// });
			
				that.preferential = await MarketingSceneService.doGetMaximumDiscountScheme(that.order.goodss);
				// console.log('that.preferential>>>>',that.preferential)
				if (that.preferential.goodss.length > 0) {
					that.counter = that.preferential.goodss.length;
					for (var i = 0; i < this.preferential.goodss.length; i++) {
						var goods = this.preferential.goodss[i];
						goods.shortDesc = "赠品";
						var cart = await CartService.doSetCount(null, goods.cartCount, goods);
						if (cart) {
							goods.cardId = cart.id;
							goods.cartCount = cart.count;
							that.order.items.push(goods);
							that.doPrePayAgain({});
						}
					}
				}
			
				var payTypes = {};
				if (that.preferential.realPrice > 0) {
					payTypes = {
						23: that.preferential.realPrice
					};
				}
				that.order.payTypes = payTypes;
				
				setTimeout(()=>{
					// 下单
					that.doPayOrder();
				},1000)
				
			},

			// 订单付款 -- 2022.5.20 新增

			async doPayOrder() {
				// 订单付款
				var payOrder = await OrderService.doRePay(this.order);
				// console.log(payOrder)
				if (payOrder) {
					// 服务券订单自动审核-订单自动提现
					var auditRes = await OrderService.doAutoAudit(this.order.id,25,'88e33dac4e6111eba3041fbe40e0a626');
					// console.log('auditRes',auditRes);
					if(auditRes.status==1){
					
						this.$message({
							showClose: true,
							message: '下单付款成功',
							type: 'success'
						});
						// 关闭弹框
						this.close();
					}
				}
			},
			

			doChooseAddress() {
				// #ifdef MP-WEIXIN
				var that = this;
				uni.chooseAddress({
					async success(res) {
						that.deliverAddress = await AddressService.doWXChooseAddress(res);
						console.log('地址',that.deliverAddress);
						that.order.receiveAddressId = that.deliverAddress.id;
						that.doPrePay();
					}
				});
				// #endif

				// #ifdef APP-PLUS
				// uni.navigateTo({
				// 	url: '/pages/goods/good_address'
				// })
				// #endif
			},
			async doWXChooseInvoiceTitle() {
				console.log('doWXChooseInvoiceTitle..............')
				var that = this;
				var invoice = await wepy.chooseInvoiceTitle();
				if (invoice.errMsg != "chooseInvoiceTitle:ok") {
					return;
				}
				var res = await InvoiceService.doWxChooseInvoice(invoice.type, invoice.title, invoice.bankName, invoice.bankAccount,
					invoice.companyAddress, invoice.telephone, invoice.taxNumber);
				if (res.status != 1) {
					return
				}
				this.invoice = res.data;
				this.order.invoiceId = this.invoice.id;
			},
			async doGetDefaultAddress() {
				var deliverAddress = await AddressService.doGetDefaultAddress(this.userId);
				if (deliverAddress) {
					this.deliverAddress = deliverAddress;
					console.log('默认地址',deliverAddress);
				}
				this.disabled = false;
				// uni.hideLoading();
				if (!this.deliverAddress) {
					return
				}
				this.order.receiveAddressId = this.deliverAddress.id;
			},
			async loginCallback(res, loginUser) {
				await this.checkUserAuth();
				this.goPaying();
			},
			async doPrePayAgain() {
				this.counter--;
				if (this.counter == 0) {
					await this.doPrePay();
				}
			},

			// async doPrePay() { //获取下单需要支付的金额和扣除的积分金额
			// 	console.log("doPrePay......");
			// 	var that = this;
			// 	this.goodss.forEach(function(goods) {
			// 		goods.sendType = that.senTypeTabList[that.senTypeTabCur].sendType;
			// 	});
			// 	this.preferential = await MarketingSceneService.doGetMaximumDiscountScheme(this.goodss);

			// 	if (this.preferential.goodss.length > 0) {
			// 		this.counter = this.preferential.goodss.length;

			// 		for (var i = 0; i < this.preferential.goodss.length; i++) {
			// 			var goods = this.preferential.goodss[i];
			// 			goods.shortDesc = "赠品";
			// 			var cart = await CartService.doSetCount(null, goods.cartCount, goods);
			// 			if (cart) {
			// 				goods.cardId = cart.id;
			// 				goods.cartCount = cart.count;
			// 				that.goodss.push(goods);
			// 				that.doPrePayAgain();
			// 			}
			// 		}
			// 	}
			// },
			cartChange(goods, count) {
				var that = this;
				this.goodss.forEach(function(item) {
					if (goods.id == item.id) {
						console.log(count + "---" + item.cartCount);
						var isChange = false;
						if (count != item.cartCount) {
							isChange = true;
						}
						item.cartCount = count;
						item.cartId = goods.cartId;
						if (isChange) {
							that.doPrePay();
						}
					}
				});

				//this.doPrePay();
			},
			computeCart() {
				console.log("compute....");
				console.log(this.goodss);
				var totalOriginalPrice = 0;
				var totalPrice = 0;
				var count = 0;
				this.goodss.forEach(function(goods) {
					count += +goods.cartCount;
					for (var i = 0; i < goods.cartCount; i++) {
						if (goods.originalPrice) {
							totalOriginalPrice += +goods.originalPrice;
						} else {
							totalOriginalPrice += +goods.presentPrice;
						}
						totalPrice += +goods.presentPrice;
					}
				});
				this.order.totalOriginalPrice = new Number(totalOriginalPrice).toFixed(2);
				this.order.totalPrice = new Number(totalPrice).toFixed(2);
				this.order.count = new Number(count).toFixed(0);
				this.order.paymentAmount = this.order.totalPrice;

				if (this.order.count == 0) {
					uni.navigateBack({
						delta: 1
					})
				}
			},
			toNearStoreList() {
				uni.navigateTo({
					url: '/pages/shop/shop_store_list?type=select&generalId=' + this.order.groupId
				})
			},
			bindBookingDateChange(date) {
				console.log("bindBookingDateChange:" + date);
				this.order.bookingDate = date;
			},
			initAgreement() {
				if (this.goodsType == 208004000000000000) {
					this.agreementTitle = "《安全检查服务协议》";
				} else if (this.goodsType == 208005000000000000) {
					if (this.senTypeTabList[this.senTypeTabCur].sendType == 2) {
						this.agreementTitle = "《年检服务协议》";
					} else if (this.senTypeTabList[this.senTypeTabCur].sendType == 3) {
						this.agreementTitle = "《上门取送车代办协议》";
					}
				} else {
					this.agreementTitle = "《服务协议》";
				}
			},
			async openAgreementDialog() {
				if (this.goodsType == 208004000000000000) {
					this.agreementTitle = "《安全检查服务协议》";
					this.article = await ArticleService.doGet("2c933e6b7489ea620174af95d06e436a");
				} else if (this.goodsType == 208005000000000000) {
					if (this.senTypeTabList[this.senTypeTabCur].sendType == 2) {
						this.agreementTitle = "《年检服务协议》";
						this.article = await ArticleService.doGet("2c93f40875470a8001755024d38b143d");
					} else if (this.senTypeTabList[this.senTypeTabCur].sendType == 3) {
						this.agreementTitle = "《上门取送车代办协议》";
						this.article = await ArticleService.doGet("2c933e6b7489ea620174af8656ce4337");
					}
				} else {
					this.agreementTitle = "《服务协议》";
					this.article = await ArticleService.doGet("2c93f408773797920177418deef61514");
				}
				this.agreementDialogShow = true;
			},
			agreementScrolltolower() {
				console.log("agreementScrolltolower...");
				this.agreementBtnEnable = true;
			},
			doAgree() {
				console.log("doAgree...");
				this.agreement = true;
        		this.agreementBtnEnable = true;
				this.agreementDialogShow = false;
			},
			// toArticle(id) {
			// 	console.log(id);
			// 	if (this.goodsType == 208004000000000000) {
			// 		uni.navigateTo({
			// 			url: "/pages/article/articleview?id=2c933e6b7489ea620174af95d06e436a"
			// 		});
			// 	} else {
			// 		uni.navigateTo({
			// 			url: "/pages/article/articleview?id=2c933e6b7489ea620174af8656ce4337"
			// 		});
			// 	}
			// },

		}
	}
</script>


<style lang="scss" scoped>
  .confirm-order-diglog{
    

    /deep/.el-dialog{
        border-radius: 8px;
        overflow:hidden ;
    }

    /deep/.el-dialog__header{
      padding:0 0 0;
    }
    /deep/.el-dialog__body{
      padding:0 0 0;
    }
    .dialog-title{
      font-size:20px;
      color: #0f71ea;
      text-align: left;
      border-bottom:2px solid #e5e5e5;
      height: 60px;
      line-height: 60px;
      padding:0 20px;
    }

    .confirm-order-content{

      .address-form-box{
          margin:0 0 0;
          padding:20px 20px 20px;

          .order-process{
            text-align: left;
            color: #0f71ea;
            cursor: pointer;
          }

          border-bottom:4px solid #e5e5e5;
      }


      .order-info-box{
        border-bottom:4px solid #e5e5e5;
        .goods-list-content{
          padding:10px 20px;
          .goods-item{
            margin-bottom:10px;
          }
        }
        

        .order-remark{
          padding:0 20px;
        }


      }
      


    }
    /deep/.el-dialog__footer{
      padding:0 20px;
    }
    .dialog-footer{
      height: 80px;
      text-align: left;
      position: relative;

      .total-price{
        height:40px;
        line-height: 40px;
        font-size:20px;
        color:#666;
        font-weight:600;
      }

      .agreement{
          font-size:16px;
          /deep/.el-checkbox__label{
              font-size:16px;
          }
      }
      .order-sub-btn{
        position: absolute;
        right:10px;
        bottom:10px;
        width: 140px;
        height:40px;
        border-radius:40px;
        font-size: 16px;

      }
    }


  }
</style>

<style lang="scss">
.agreement-dialog{
    .agreement-art{
      margin-bottom:40px;
    }

    .agree-btn{
      width: 100%;
      padding:0 20px;
      height: 40px;
      line-height: 40px;
      font-size: 16px;
    }

}
</style>