<template>
    <div class="default-wrap">
        <div class="body-content-wrap main-news-wrap">

                <div class="news-banner-box" :style="{  backgroundImage:'url(https://splatformnew.oss-cn-shanghai.aliyuncs.com/zhiguoguo/news_banner.png)',backgroundPosition: 'center center',backgroundRepeat:'no-repeat',backgroundSize:'cover' }">
                    <!-- <img :src="newsBanner" alt="新闻公告"> -->
                </div>

                <div class="content-wrap clearfix">

                    <div class="news-category left">
                        <el-radio-group v-model="current" size="small" @change="change">
                            <el-radio :label="index" border v-for="(item,index) in TabList" :key="'tab'+index">{{item.name}}</el-radio>
                        </el-radio-group>
                    </div>

                    <div class="news-list-cont right">
                        <div class="news-list">
                            <div class="news-item" v-for="(item,index) in pageList" :key="index" @click="goDetail(index)">
                                <div class="news-img">
                                    <el-image
                                    style="width: 280px; height: 135px"
                                    :src="item.showPicUrl"
                                    :title="item.title"
                                    fit="cover"></el-image>
                                </div>
                                <div class="new-detail">
                                    <div class="news-title">
                                        {{item.title}}
                                    </div>
                                    <div class="news-other">
                                        <div class="news-author">
                                            {{item.author}}
                                        </div>
                                        <div class="news-date">
                                            {{item.time}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        

                        <div class="pagination">
                            <el-pagination
                                background
                                :hide-on-single-page="true"
                                :page-size="count"
                                layout="prev, pager, next"
                                :total="total"
                                :current-page.sync="page"
                                @current-change="handleCurrentChange">
                            </el-pagination>
                        </div>
                    </div>

                </div>
        </div>
    </div>
</template>

<script>
var dateUtils = require('../../common/newsUtil.js').dateUtils;
import ArticleService from '../../services/ArticleService.js'
import TimeUtil from '../../common/TimeUtil.js'
import pagingMixin from '../../mixins/Paging.js'
export default{
    mixins: [pagingMixin],
    data(){
        return {
            // 新闻公告banner
            newsBanner:require('../../assets/img/news_banner.png'),
            TabList: [],
            current: 0,
            type:0,
            page: 1,
            count: 6,
            categoryId:''
            
        }
    },
    components:{
        
    },
    computed: {
        NewsList() {
            if (this.pageList.length) {
                this.pageList.forEach((item, index) => {
                item.time = TimeUtil.formatSpecificTime(item.creatTime, 'Y-M-D H:m:s')
                })
                return this.pageList
            }
        }
    },
    created() {
        this.doGetNewsCategory()
    },
    onLoad() {

    },
    methods: {

        handleCurrentChange(val) {
            // console.log(`当前页: ${val}`);
            this.page=val;
            this.doGetNews(this.categoryId);
        },

        async doGetNewsCategory() {
            var that = this;
            var res = await ArticleService.doGetNewsCategory();
            if (res.data) {
                var tabs = [];
                res.data.forEach(function (item, index) {
                    if(that.type == 0){
                        if(item.name.length < 4) {
                            tabs.push(item);
                        }
                    } else {
                        if(item.name.length > 3) {
                            tabs.push(item);
                        }
                    }
                });
                this.TabList = tabs;
                this.categoryId=this.TabList[0].id;
                this.doGetNews(this.TabList[0].id)
            }
        },
        async doGetNews(categoryId) {
            this.pagePlugin = {
                api: ArticleService.doGetNews,
                options: [categoryId, this.page, this.count]
            }
            this.initializePageList()
        },
        change(index) {
            this.current = index;
            this.categoryId=this.TabList[index].id;
            this.page=1;
            this.doGetNews(this.TabList[index].id)
        },

  
        goDetail(index) {
            // uni.navigateTo({
            //     url: '../News/NewsDetail?detailDate=' + encodeURIComponent(JSON.stringify(this.NewsList[index]))
            // })
            let art=this.NewsList[index];
            this.$router.push({
                name:'newDetail',
                params:{
                    art:art
                }
            });
        },
    }
	
}
</script>

<style lang="scss" scoped>


.main-news-wrap{
    min-width: 1100px;
    position: relative;
    margin-top: 68px;

    .news-banner-box{
        position: relative;
        height: 430px;
        width:100%;
    
        img{
            width:100%;
        }
    }

    .news-category{
        padding-top:50px;
        width:200px;
        margin-right: 25px;

        .el-radio-group{
            /deep/.el-radio__input{
                display:none;
            }
            
            .el-radio{
                display: block;
                margin-bottom: 20px;
                height: 40px;
                line-height: 40px;
                padding:0 15px;
                width: 140px;
                text-align: center;
                border:none;
                border-radius:5px;
            }

            .el-radio.is-checked{
                box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
                /deep/.el-radio__label{
                    font-weight: bold;
                }
            }

            .el-radio.is-bordered+.el-radio.is-bordered{
                margin-left: 0;
            }

            /deep/.el-radio__label{
                font-size:20px;
                padding-left: 0;
            }


        }   
    }
   
    .news-list-cont{
        width:calc(100% - 225px);
        min-height: 600px;
        .news-list{
            min-height:200px;
            .news-item{
                display:flex;
                padding:40px 0 40px;
                border-bottom:2px solid #d2d2d2;
                position: relative;
                cursor: pointer;
                

                .news-img{
                    width:280px;
                    height:135px;
                    margin-right: 20px;
                    border-radius: 5px;
                    overflow: hidden;
                    // img{
                    //     width:100%;
                    // }
                }
                .new-detail{
                    width: calc(100% - 235px);
                    text-align: left;
                    
                    position: relative;

                    .news-title{
                        font-weight: bold;
                        line-height: 36px;
                        font-size: 20px;
                        color:#333;
                    }

                    .news-other{
                        position: absolute;
                        bottom: 0;
                        width: 100%;
                        font-size: 18px;
                    }
                    .news-author,.news-date{
                        color:#999;
                        display: inline-block;
                        width:50%;
                    }
                    .news-date{
                        text-align: right;
                        font-size: 16px;
                    }

                }
            }
            .news-item:last-child{
                border:none;
            }
        }


        .pagination{
            margin:0 auto 60px;
        }
        
    }
    
}
</style>