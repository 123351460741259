<template>
  <div class="default-wrap">
    <div class="body-content-wrap main-coupon-wrap">
      <div
        class="coupon-banner-box"
        :style="{
          backgroundImage:
            'url(https://splatformnew.oss-cn-shanghai.aliyuncs.com/zhiguoguo/coupon_banner.png)',
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }"
      >
        <!-- <img :src="couponBanner" alt="创新劵"> -->
      </div>

      <div class="banner-card-box">
        <div class="banner-card-content">
          <div class="card-col col-left">
            <div class="tit">{{ money.actual }}万元</div>
            <div class="sub-tit">发劵金额 (元)</div>
          </div>
          <div class="card-col col-right">
            <div class="tit">{{ money.expect }}万元</div>
            <div class="sub-tit">兑付金额 (元)</div>
          </div>
        </div>
      </div>

      <div class="coupon-card-box">
        <div
          class="card-item"
          v-for="(item, index) in couponCards"
          :key="index"
          @click="gotoRoute(index)"
        >
          <div class="card-img">
            <img :src="item.imgUrl" alt="item.title" />
          </div>
          <div class="card-txt">
            {{ item.title }}
          </div>
        </div>
      </div>

      <div
        class="total-box"
        :style="{
          backgroundImage:
            'url(https://splatformnew.oss-cn-shanghai.aliyuncs.com/zhiguoguo/recommend_bg.png)',
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }"
      >
        <div class="total-content">
          <div class="total-item" v-for="(item, index) in totals" :key="index">
            <div class="total-detail">
              <div class="total-icon">
                <img :src="item.imgUrl" alt="item.title" />
              </div>
              <div class="total-num">
                {{ item.count }}
              </div>
            </div>
            <div class="total-tit">
              {{ item.title }}
            </div>
          </div>
        </div>
      </div>

      <div class="notice-box">
        <div class="common-title">
          <div class="title-top">公示</div>
          <h3>RELEASE</h3>
        </div>

        <su-news-box
          title="公示列表"
          :categoryId="categoryId"
          :count="3"
        ></su-news-box>
      </div>
    </div>
  </div>
</template>

<script>
import suNewsBox from "@/components/su-news-box/su-news-box.vue";

import TimeUtil from "../../common/TimeUtil.js";
import Config from "../../common/Config.js";
import ArticleService from "../../services/ArticleService.js";
import OrderService from "../../services/OrderService.js";
import CouponService from "../../services/CouponService";
import DataCenterService from "../../services/DataCenterService";
import ConfigUtil from "../../common/Config.js";
import UserService from "@/services/UserService";
import uni from "@/services/UNIService";

export default {
  components: {
    suNewsBox,
  },
  data() {
    return {
      orderSearchModel: {
        businessTypes: 4026000000000000,
        orderStatus: 4,
        refundStatus: 1,
      },
      money: {
        expect: "-",
        actual: "-",
      },
      couponLog: {
        logType: 10,
        groupId: ConfigUtil.getBusinessTradingAreaId(),
      },
      buttonList: [
        {
          text: "青年大学生创业人才企业领券通道",
          id: 0,
        },
        {
          text: "高层次创业人才企业领券通道",
          id: 1,
        },
        {
          text: "瞪羚、（培育）独角兽企业领券通道",
          id: 2,
        },
        {
          text: "其他类型项目领券通道",
          id: 3,
        },
      ],
      buttonList2: [
        {
          text: "普通中国高校在校生",
          id: 4,
        },
        {
          text: "毕业5年内中国高校毕业生",
          id: 5,
        },
        {
          text: "留学回国的青年留学人员",
          id: 6,
        },
      ],

      couponCards: [
        {
          index: 0,
          imgUrl: require("../../assets/img/coupon_card_icon_1.png"),
          title: "机构入驻",
          name: "institutionSettled",
          value: "",
        },
        {
          index: 1,
          imgUrl: require("../../assets/img/coupon_card_icon_2.png"),
          title: "我要申领",
          name: "applyCoupon",
          value: "",
        },
        {
          index: 2,
          imgUrl: require("../../assets/img/coupon_card_icon_3.png"),
          title: "用券专区",
          name: "mall",
          value: "3",
        },
      ],
      totals: [
        {
          id: 4,
          imgUrl: require("../../assets/img/total_icon_1.png"),
          title: "科技企业",
          count: "1000",
        },
        {
          id: 5,
          imgUrl: require("../../assets/img/total_icon_2.png"),
          title: "创新产品",
          count: "210",
        },
        {
          id: 6,
          imgUrl: require("../../assets/img/total_icon_3.png"),
          title: "公共技术平台",
          count: "40",
        },
        {
          id: 1,
          imgUrl: require("../../assets/img/total_icon_4.png"),
          title: "新型研发机构",
          count: "40",
        },
        {
          id: 2,
          imgUrl: require("../../assets/img/total_icon_5.png"),
          title: "海外顶尖高效",
          count: "11",
        },
        {
          id: 3,
          imgUrl: require("../../assets/img/total_icon_6.png"),
          title: "国内大院大所",
          count: "28",
        },
      ],

      // 公示ID
      categoryId: 245000000000000000,

      // notice
      NewsList: [],
    };
  },
  created() {
    // let categoryId=245000000000000000;
    // this.doGetNews(categoryId),
    this.doStatisticalRegimentalAmountNew(this.orderSearchModel),
      this.doGetConsumeAmount(this.couponLog);
  },
  methods: {
    gotoRoute(index) {
      // if(index==1){
      //   this.$confirm('系统优化升级中，领券功能暂时关闭，敬请期待！', '提示', {
      //       confirmButtonText: '确定',
      //       cancelButtonText: '取消',
      //       type: 'warning'
      //     }).then(() => {
      //       console.log('确定')
      //     }).catch(() => {
      //       console.log('取消')      
      //     });
      //     return;
      // }

      if (this.couponCards[index]["index"] == 1 &&!UserService.checkUserAuth()) {
        this.$message({
          message: "请先登录",
          type: "warning",
        });

        setTimeout(() => {
          this.$router.push({
            path: "/login",
          });
        }, 1500);

        return;
      }

      if (
        this.couponCards[index]["index"] == 1 &&
        UserService.checkUserAuth()
      ) {
        this.doGetAccount();
        return;
      }

      // console.log("===========================");
      this.$router.push({
        name: this.couponCards[index]["name"],
        params: {
          type: this.couponCards[index]["value"],
        },
      });
    },

    async doGetAccount() {
      //金额
      var exchangeAmountInfo = await CouponService.doGetAccount(
        Config.getBusinessTradingAreaId(),
        "account",
        uni.getStorageSync("userId"),
        1006
      );
      if (exchangeAmountInfo.status == 1 && exchangeAmountInfo.data.value > 0) {
        this.$router.push({
          path: "/my/myCoupons",
        });
        // this.show = true
      } else {
        // this.show = true;
        this.$router.push({
          path: "/coupon/applyCoupon",
        });
      }
    },

    btnListTaped(item) {
      if (item.id == 0) {
        this.show2 = true;
      } else {
        uni.navigateTo({
          url: "../Coupon/CouponApply?index=" + item.id,
        });
      }
    },
    callPhone() {
      uni.makePhoneCall({
        phoneNumber: "13182987339",
      });
    },
    // async doGetAccount() { //金额
    //     var exchangeAmountInfo = await CouponService.doGetAccount(Config.getBusinessTradingAreaId(), 'account', uni.getStorageSync(
    //         "userId"), 1006);
    //     if (exchangeAmountInfo.status == 1 && exchangeAmountInfo.data.value > 0) {
    //         //
    //         // this.resultModalShow = true;
    //         // this.resultContent = "已领取创新券";
    //         uni.navigateTo({
    //             url: '../MyCoupon/MyCoupon'
    //         })
    //         // this.show = true
    //     } else {
    //         this.show = true
    //     }

    // },
    onSkip(index) {
      switch (index) {
        case 1:
          uni.navigateTo({
            url: "../CompanySettlement/CompanySettlement",
          });
          break;
        case 2:
          this.doGetAccount();
          break;
        case 4:
          uni.navigateTo({
            url: "../CouponGoodsList/CouponGoodsList",
          });
          break;
        default:
          break;
      }
    },
    async doGetNewsCategory() {
      var res = await ArticleService.doGetNewsCategory();
      if (res.data.length) {
        var tabs = [];
        res.data.forEach(function (item, index) {
          if (item.name.length < 4) {
            tabs.push(item);
          }
        });
        this.TabList = tabs;
        // console.log(this.TabList)
        this.doGetNews(this.TabList[0].id);
      }
    },
    async doStatisticalRegimentalAmountNew(orderSearchModel) {

        // var res = await OrderService.doStatisticalRegimentalAmountNew(
        //     orderSearchModel
        // );

        let couponLog={
            logType:1,
            groupId:'2c93f40876be5e9e0176cc60649366ce'
        }
        let res=await DataCenterService.doGetConsumeAmount(couponLog);
        
        if(res.status==1){
            this.money.actual=parseFloat(res.data / 10000);
        }
      
        this.$forceUpdate();
    },
    async doGetConsumeAmount(couponLog) {
      var res = await OrderService.doGetConsumeAmount(couponLog);
      if (res.status) {
        this.money.expect = parseInt(res.data / 10000);
      }
      this.$forceUpdate();
    },

    async doGetNews(categoryId) {
      var res = await ArticleService.doGetNews(categoryId);
      if (res.status == 1 && res.data.length) {
        res.data.forEach((item, index) => {
          item.time = TimeUtil.formatSpecificTime(
            item.creatTime,
            "Y-M-D H:m:s"
          );
        });

        this.NewsList = res.data;
      } else {
        this.NewsList = [];
      }
    },
    change(index) {
      this.current = index;
      this.doGetNews(this.TabList[index].id);
    },
  },
};
</script>

<style lang="scss" scoped>
.main-coupon-wrap {
  min-width: 1100px;
  position: relative;
  width: 100%;
  margin-top: 68px;

  .coupon-banner-box {
    width: 100%;
    height: 430px;
    position: relative;
    top: 0;
    img {
      width: 100%;
    }
  }

  .banner-card-box {
    width: 100%;
    height: 180px;
    position: relative;
    top: -90px;
    z-index: 50;

    .banner-card-content {
      position: relative;
      width: 900px;
      height: 180px;

      margin: 0 auto 0;
      border-radius: 15px;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      padding: 22px 0;
      display: flex;
      background: #fff;

      .card-col {
        width: 50%;
        text-align: center;

        .tit {
          height: 90px;
          line-height: 90px;
          font-size: 46px;
          color: #000;
          font-weight: bold;
        }
        .sub-tit {
          height: 46px;
          line-height: 46px;
          font-size: 26px;
          color: #333;
        }
      }

      .col-left {
        border-right: 1px solid #eee;
      }
      .col-right {
        border-left: 1px solid #eee;
      }
    }
  }

  .coupon-card-box {
    width: 900px;
    margin: 0 auto 60px;

    display: flex;
    justify-content: space-between;
    .card-item {
      cursor: pointer;

      .card-img {
        width: 80px;
        height: 80px;
        img {
          width: 100%;
        }
      }

      .card-txt {
        margin-top: 10px;
        font-size: 22px;
      }
    }
  }

  .total-box {
    position: relative;
    height: 460px;

    .total-content {
      width: 900px;
      margin: 0 auto;
      padding-top: 80px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .total-item {
        width: 210px;
        margin-right: 20px;
        margin-bottom: 60px;
        text-align: left;

        .total-detail {
          width: 100%;

          .total-icon {
            display: inline-block;
            width: 70px;
            height: 70px;
            vertical-align: middle;

            img {
              width: 70px;
              height: 70px;
            }
          }

          .total-num {
            vertical-align: middle;
            display: inline-block;
            width: calc(100% - 90px);
            height: 60px;
            margin-left: 20px;
            text-align: right;
            line-height: 60px;
            font-size: 46px;
            color: #fff;
          }
        }

        .total-tit {
          margin-top: 20px;
          font-size: 26px;
          color: #fff;
        }
      }
    }
  }

  .notice-box {
    padding-top: 50px;

    .common-title {
      .title-top::before {
        margin-left: -100px;
      }
      .title-top::after {
        margin-left: 75px;
      }
    }
  }
}
</style>