<template>
    <div class="product-list-box">
        <div class="product-list-content" >
            <div  class="product-item" v-for="(item,index) in pageList" :key="index" @click="toGoodsDetail2(item.id)">

                <div class="product-img">
                    <el-image
                        style="width:210;height:210px;"
                        :src="item.showUrl"
                        :alt="item.title"
                        :title="item.title"
                        fit="contain"></el-image>
                    <img v-if="item.discountStatus" :src="require('../../assets/img/coupon_icon.png')"  class="product-coupon" alt="创新优惠券">
                </div>
                <div class="product-title" :title="item.title">
                    {{item.title}}
                </div>
                <div class="product-price">
                    价格 : <span class="price"> <span style="font-size:16px;"> ¥ </span>{{item.presentPrice}}</span> {{item.unitName}}
                </div>
                <div class="product-desc">
                    <div v-html="highlight(item.goodsDesc)"></div>
                </div>
                
            </div>

             <su-empty v-if="pageList.length==0" :txt="'没有更多内容~'"></su-empty>
        </div>

        <div class="pagination" v-if="pageList.length">
            <el-pagination
                background
                :hide-on-single-page="true"
                :page-size="count"
                layout="prev, pager, next"
                :total="total"
                :current-page.sync="page"
                 @current-change="handleCurrentChange">
            </el-pagination>
        </div>
        
       

    </div>
    
</template>

<script>
    import suEmpty from "@/components/others/su-empty.vue"
	import Pageing from "../../mixins/Paging";
	import GoodsService from '../../services/GoodsService';

	export default {
		name: 'GoodsList',
		components: {suEmpty},
		mixins: [Pageing],
		data() {
			return {
				page: 1,
				count: 12
			}
		},
		props: {
			isList: Boolean,
			searchModel: Object,
		},
        computed:{
           
        },
		watch: {
            'searchModel.categoryId'(value) {
                //  console.log("page 改变了....");
                this.page=1;
                 this.doPage();
            },
            'searchModel.discountStatus'(value) {
                //  console.log("page 改变了....");
                this.page=1;
                 this.doPage();
            },
            'searchModel.sortExp'(value) {
                // console.log("page 改变了....");
                this.page=1;
                 this.doPage();
            },
            'searchModel.titleKeyword'(value) {
                console.log("searchModel",this.searchModel);
                this.page=1;
                this.doPage();
            },
            // searchModel:{
            //     handler:(val,oldVal)=>{
            //           console.log("su-good-list.vue watch  searchModel change....");

            //           vm.page=1;
            //             //要执行的任务
            //             //这里不知道怎么才能修改到this.data的数据，有知道的麻烦告知
            //             //现在知道的就是通过直接修改Store.state的方式来更新数据，当然效果和修改this.data是一样的
            //         },
            //         // 深度观察
            //         deep:true,
            //         immediate: true
            // },
            // searchModel: {
            //     handler: function(val) { // 此处注意，handler函数不能为箭头函数，this会取上下文，而不是组件里的this,此外，深度监听，必须为handler函数名，否则会无效果
            //         console.log("su-good-list.vue watch  searchModel change....");
            //         this.doPage();
            //     },
            //     deep: true
            // },
            
		},
		mounted() {
			// console.log("su-good-list.vue mounted")
			// var that = this;
			// uni.$on('goodList', function(msg) {
			// 	console.log("su-good-list.vue created onNext");
			// 	that.doNextPage();
			// })
            this.doPage();
		},
		beforeDestroy() {
			// console.log("su-good-list.vue beforeDestroy~~~")
			// uni.$off('goodList', function() {});
		},
		methods: {
            setPage(){
                this.page=1;
            },
            handleCurrentChange(val) {
                // console.log(`当前页: ${val}`);
                this.page=val;
                this.doPage();
            },

            highlight (item) {
                if(item){
                    return item.replace(/<[^>]+>/g, ' ')// 去掉所有的html标记
                }else{
                    return '暂无描述';
                }
                // const removehtml = (str = '') => str.replace(/<[/!][^<>]>/gi, '')
            },

            toGoodsDetail2(id){
                this.$router.push({
                    path:'/mall/pdtDetail',
                    query:{
                        goodsId:id
                    }
                });
            },

			dataFilter(goodss) {
				goodss.forEach(function(goods) {
					if (goods.showUrl) {
						goods.showUrl = goods.showUrl.split(',')[0];
						goods.showUrl = goods.showUrl.replace(/(.*)xx_l/, '$1x1_l')
					} else {
						goods.showUrl = 'https://splatformnew.oss-cn-shanghai.aliyuncs.com/zhiguoguo/goods_default.png'
					}
				});
				return goodss;
			},
			async doPage() {
				this.pagePlugin = {
					api: GoodsService.doPageBySearchModel,
					options: [
						this.searchModel,
						this.page,
						this.count
					]
				};
				await this.initializePageList();
			},
			// doNextPage() {
			// 	console.log("doNextPage.....");
			// 	if (this.loadingStatus == 'nomore') {
			// 		return;
			// 	}
			// 	console.log("doNextPaged.....");
			// 	this.loadingStatus = 'loading';
			// 	this.currentPage += 1;
			// 	this.doPageList();
			// },
			toGoodsDetail(goods) {
				var url = "/pages/GoodsDetails/GoodsDetails?goodsId=" + goods.id;
				console.log("toGoodsDetail....", url);
				uni.navigateTo({
					url: url
				});
			}
		},
	}
</script>

<style lang="scss" scoped>
.product-list-content{
    display: flex;
    justify-content:flex-start;
    flex-wrap: wrap;
    width: 100%;
    padding:0;
    margin:20px 0 10px;
    min-height:600px;


    .product-item{
        position: relative;
        margin: 0 10px 30px ;
        background: #fff;
        border-radius: 20px;
        width: 210px;
        height:370px;
        border-radius: 8px;
        overflow: hidden;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        cursor: pointer;

        .product-img{
            position: relative;
            width: 100%;
            img{
                width: 100%;
                position: relative;
            }

            .product-coupon{
                position: absolute;
                top:15px;
                right:15px;
                z-index: 20;
                width:80px;
                height:24px; 

            }
        }
        .product-title{
            font-weight: bold;
            padding:0 10px;
            margin: 4px 0 4px;
            height: 28px;
            line-height: 28px;
            font-size: 20px;
            color:#333;
            text-align: left;
            overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;
        }

        .product-price{
            text-align: left;
            padding:0 10px;
            font-size:14px;
            color:#999;
            height: 26px;
            line-height: 26px;
            margin-bottom: 5px;
            overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;

            .price{
                font-size: 20px;
                color:#e61414;
                font-weight:800px;
            }
        }
        .product-desc{
            padding:0 10px;
            color:#999;
            font-size:14px;
            line-height:24px;
            text-align:left;
            min-height:72px;
            overflow : hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }
        .product-manufacturer,.product-pattern,.product-type,.product-address{
            font-size:14px;
            color:#999;
            height: 24px;
            line-height: 24px;
            text-align: left;
            padding:0 10px;
        }
        

    }
}

.pagination{
    margin:0 auto 60px;
}
</style>