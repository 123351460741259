<template>
     <div class="recommend-list-content">
        <div class="product-item" v-for="(item,index) in pageList" :key="index" @click="toGoodsDetail2(item.id)">
            <div class="product-img">
                <el-image
                    style="width:210px;height:210px;"
                    :src="item.showUrl"
                    :alt="item.title"
                    fit="contain"></el-image>
                <img v-if="item.discountStatus" :src="require('../../assets/img/coupon_icon.png')" class="product-coupon" alt="创新优惠券">
            </div>
            <div class="product-title">
                {{item.title}}
            </div>
            <div class="product-price">
                价格 : <span class="price"> <span style="font-size:16px;"> ¥ </span>{{item.presentPrice}}</span> {{item.unitName}}
            </div>
            <div class="product-desc">
                <div v-html="highlight(item.goodsDesc)"></div>
            </div>
        </div>

        <su-empty v-if="pageList.length==0" :txt="'暂无更多排行榜内容'"></su-empty>

        
    </div>
</template>


<script>
import suEmpty from "@/components/others/su-empty.vue"
import Pageing from "../../mixins/Paging";
import GoodsService from '../../services/GoodsService';
import CommonUtil from '../../common/Common';
import uni from '../../services/UNIService'


export default {
  name: 'GoodsList',
  components: {suEmpty},
  mixins: [Pageing],
  data() {
    return{
      page:1,

   

    }
  },
  props: {
    searchModel:Object,
    title:String,
    desc:String,
    moreText:String,
    marginlr:{
      type: String,
      default() {
        return "30rpx";
      }
    },
	type:Number,
	  count:Number
  },
  watch:{
    searchModel(value){
    //   console.log("su-item-good-list.vue watch  searchModel change....");
      this.doPage();
    }
  },
  mounted(){
    // var that = this;
    // uni.$on('onNext',function(msg){
    //   console.log("su-item-good-list.vue mounted onNext");
    //   that.doNextPage();
    // })
  },
  beforeDestroy(){
    // uni.$off('onNext', function () {});
  },
    methods: {
        highlight (item) {
            if(item){
                return item.replace(/<[^>]+>/g, ' ')// 去掉所有的html标记
            }else{
                return '暂无描述';
            }
            // const removehtml = (str = '') => str.replace(/<[/!][^<>]>/gi, '')
        },
        toGoodsDetail2(id){
            // console.log(id)
            this.$router.push({
                path:'/mall/pdtDetail',
                query:{
                    goodsId:id
                }
            });
        },

        toNext(){
            console.log(this.searchModel)
            uni.navigateTo({
                url:'../../pages/Search/Search?type='+ this.type
            })
        },
        dataFilter(goodss) {
            goodss.forEach(function (goods) {
                if(goods.showUrl) {
                goods.showUrl = goods.showUrl.split(',')[0];
                } else {
                goods.showUrl = 'https://splatformnew.oss-cn-shanghai.aliyuncs.com/zhiguoguo/goods_default.png'
                }
            });
            return goodss;
        },
        async doPage() {
            this.pagePlugin = {
                api: GoodsService.doPageBySearchModel,
                options: [
                    this.searchModel,
                    this.page,
                    this.count
                ]
            };
            await this.initializePageList();
        },
        doNextPage() {
            console.log("doNextPage.....");
            if (this.loadingStatus == 'nomore') {
                return;
            }
            console.log("doNextPaged.....");
            this.loadingStatus = 'loading';
            this.currentPage += 1;
            this.doPageList();
            },
            toGoodsDetail(goods) {
            var url = "/pages/GoodsDetails/GoodsDetails?goodsId="+goods.id;
            console.log("toGoodsDetail....", url);
            uni.navigateTo({
                url: url
            });
        }
  },
}
</script>

<style lang="scss" scoped>
.recommend-list-content{
            
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 1000px;
    padding:0 30px;
    margin:30px auto 60px;


    .product-item{
        position: relative;
        margin: 0 10px 35px ;
        background: #fff;
        border-radius: 20px;
        width: 210px;
        height:370px;
        border-radius: 15px;
        overflow: hidden;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        cursor: pointer;

        .product-img{
            position: relative;
            width: 100%;
            img{
                width: 100%;
                position: relative;
            }

            .product-coupon{
                position: absolute;
                top:15px;
                right:15px;
                z-index: 20;
                width:80px;
                height:24px; 

            }
        }
        .product-title{
            font-weight: bold;
            padding:0 10px;
            margin: 8px 0 4px;
            height: 32px;
            line-height: 32px;
            font-size: 22px;
            color:#333;
            text-align: left;
            overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;

        }

        .product-price{
            text-align: left;
            padding:0 10px;
            font-size:14px;
            color:#999;
            height: 26px;
            line-height: 26px;
            margin-bottom: 5px;
            overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;

            .price{
                font-size: 20px;
                color:#e61414;
                font-weight:800px;
            }
        }
        .product-desc{
            padding:0 10px;
            color:#999;
            font-size:14px;
            line-height:24px;
            text-align:left;
            min-height:72px;
            overflow : hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }

        

        
    }
        
}
</style>