<template>
    <div class="default-wrap">
        
        <div class="body-content-wrap main-institution-settled-wrap">

             <div class="institution-settled-banner-box" :style="{  backgroundImage:'url(https://splatformnew.oss-cn-shanghai.aliyuncs.com/zhiguoguo/institution_settled_banner.png)',backgroundPosition: 'center center',backgroundRepeat:'no-repeat',backgroundSize:'cover' }" >
                    
            </div>

            <div class="settled-form-box">
                <div class="content-wrap">
                    <el-form ref="uForm" :rules="rules" :model="form" label-width="80px" class="settled-form" >
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="公司名称" prop="name">
                                    <el-input v-model="form.name" placeholder="请输入公司名称" auto-complete="off"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="公司地址" prop="address">
                                    <el-input v-model="form.address" placeholder="请输入公司地址" auto-complete="off"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="联系人" prop="managerName">
                                    <el-input v-model="form.managerName" placeholder="请输入您的姓名" auto-complete="off"></el-input>
                                </el-form-item>
                            </el-col>

                            <el-col :span="12">
                                <el-form-item label="联系电话" prop="phone">
                                    <el-input v-model="form.phone" placeholder="请输入联系电话" auto-complete="off"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                         
                                <el-form-item label="营业执照" prop="attachmentUrl">
                                    <el-upload
                                        class="upload-img"
                                        :show-file-list="true"
                                        :action="uploadServiceUrl"
                                        :before-upload="beforeAvatarUpload"
                                        :on-success="handleAvatarSuccess">
                                        <img v-if="form.attachmentUrl" :src="form.attachmentUrl" class="avatar">
                                        <i v-else class="el-icon-plus uploader-icon"></i>
                                    </el-upload>
                                </el-form-item>
                      
                        </el-row>
                        <el-form-item class="btn-tools">

                            <el-button @click="resetForm">重置</el-button>
                            <el-button type="primary" @click="submit"> 提 交 </el-button>

                        </el-form-item>
                    </el-form>
                </div>
                

            </div>

        </div>
    </div>
            
</template>

<script>


	import GroupService from '../../services/GroupService.js'
	import UserService from '../../services/UserService.js'
	import config from '../../common/Config.js'
	export default {
        components:{
       
        },
		data() {
			return {
                uploadServiceUrl: config.getPath() + 'file_uploadImg.do?key=system.image.processor.1xxl',
				form: {
					name: '',
					address: '',
					phone: '',
					managerName: '',
					attachmentUrl: '',
					tradingAreaId: config.getBusinessTradingAreaId(),
					roleIds: '2c93f4087716344001771b2715a20a57',
				},
				bindGroup: {
					account: ""
				},
				rules: {
					name: [{
						required: true,
						message: '请输入公司名称',
						trigger: ['change', 'blur'],
					}, ],
					address: [{
						required: true,
						message: '请输入公司地址',
						trigger: ['change', 'blur'],
					}, ],
					managerName: [{
						required: true,
						message: '请输入联系人姓名',
						trigger: ['change', 'blur'],
					}, ],
					attachmentUrl: [{
						required: true,
						message: '请上传营业执照',
						trigger: ['change', 'blur'],
					}, ],
					phone: [{
							required: true,
							message: '请输入手机号',
							trigger: ['change', 'blur'],
						},
						// {
						// 	validator: (rule, value, callback) => {
						// 		// 调用uView自带的js验证规则，详见：https://www.uviewui.com/js/test.html
						// 		return this.$u.test.mobile(value);
						// 	},
						// 	message: '手机号码不正确',
						// 	// 触发器可以同时用blur和change，二者之间用英文逗号隔开
						// 	trigger: ['change', 'blur'],
						// }
					],

                    
				},
			};
		},
		async onLoad(params) {

		},
		onReady() {

		},
		created() {
			// this.checkBind()
		},
		methods: {
			copy() {
				uni.setClipboardData({
					data: "http://www.suconnected.com/zhiguoguo/#/login"
				});
			},
			// async checkBind() {
			// 	var res = await UserService.checkBind()
			// 	if (res) {
			// 		this.bindGroup = res
			// 	} else {
			// 		// this.$refs.uForm.setRules(this.rules);
			// 	}
			// },

            beforeAvatarUpload(file) {
              
                if(!(file.type == 'image/jpeg' || file.type == 'image/png')){
                    this.$message.error('上传头像图片只能是 JPG 或 PNG 格式!');
                    return false;
                }

                // const isJPG = file.type === 'image/jpeg' || 'image/png';
                // const isLt2M = file.size / 1024 / 1024 < 2;
                // if (!isJPG) {
                //     this.$message.error('上传头像图片只能是 JPG 或 PNG 格式!');
                // }
                // if (!isLt2M) {
                //     this.$message.error('上传头像图片大小不能超过 2MB!');
                // }
                // return isJPG && isLt2M;
            },

            handleAvatarSuccess(res) { //图片上传成功的回调
                // console.log(res);
                this.form.attachmentUrl=res.absolute;
                this.$forceUpdate()
            },
			// uploadSuccess(itemsIndex, absoluteUrlList, relativeUrlList, item, moduleId, itemIndex) {
			// 	if (absoluteUrlList.length == 0) {
			// 		this.remove(absoluteUrlList, itemIndex);
			// 		console.log('remove')
			// 	} else {
			// 		this.form.attachmentUrl = absoluteUrlList.join(","),
			// 			this.$forceUpdate()
			// 	}
			// },
			// remove(absoluteUrlList, index) {
			// 	absoluteUrlList.splice(index, 1);
			// },

            resetForm(){
                // this.
                this.$refs['uForm'].resetFields();
            },

			submit() {
                if (!UserService.checkUserAuth()) {
                    this.$message({
                        message: '请先登录',
                        type: 'warning'
                    });
                    setTimeout(()=>{
                        this.$router.push({
                            path:'/login'
                        })
                    },1500)
                    return false;
                }

				this.$refs.uForm.validate((valid) => {
					if (valid) {
						this.doAdd()
					} else {
						console.log('验证失败');
					}
				});
			},
			async doAdd() {
				var res = await GroupService.doAddUserGroup(this.form)
				if (res.status == 1) {
					// uni.showToast({
					// 	title:'店铺添加成功~~~'
					// })

                    this.$message({
                        message: '店铺添加成功~~~',
                        type: 'success'
                    });

                    this.$refs.uForm.resetFields();
					this.checkBind()
				}
			},
		}
	};

</script>

<style lang="scss" scoped>
.main-institution-settled-wrap{
    min-width: 1100px;
    position: relative;
    width: 100%;
    margin-top: 68px;

     .institution-settled-banner-box{ 
        width:100%;
        height: 430px;
        position: relative;  
    }

    .settled-form-box{
        margin: 40px 0 50px;

        .settled-form{
            width:900px;
            margin:0 auto;

            .upload-img{
                text-align: left;
                /deep/.el-upload {
                    border: 1px dashed #d9d9d9;
                    border-radius: 6px;
                    cursor: pointer;
                    position: relative;
                    overflow: hidden;
                }

                .el-upload:hover {
                    border-color: #409EFF;
                }

                 .uploader-icon {
                    font-size: 28px;
                    color: #8c939d;
                    width: 140px;
                    height: 140px;
                    line-height: 140px;
                    text-align: center;
                }

                    .avatar {
                        width: 140px;
                        height: 140px;
                        display: block;
                    }
            } 
           
          
            .btn-tools{
                .el-form-item__content{
                    width:100%;
                }
                .el-button{
                    width:120px;
                    margin:0 20px;
                    padding:0 20px;
                    height:38px;
                    line-height:36px;
                }
            }

        }
    }

}

</style>

<style lang="scss">
.avatar-uploader{
    text-align: left;

    .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .el-upload:hover {
        border-color: #409EFF;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 148px;
        height: 148px;
        line-height: 148px;
        text-align: center;
    }
    .avatar {
        width: 148px;
        height: 148px;
        display: block;
    }
}



.upload-img{
	.el-upload-list{
		.el-upload-list__item-name{
			display: none;
		}
		.el-upload-list__item-status-label{
			display:none;
		}
		.el-progress{
			margin-top:-40px;
		}
		
	}
	// .file-uplode-tit{
	// 	color:#0f71ea;
	// }
	// .el-icon-upload{
	// 	margin:5px 0 0;
	// 	color:#ccc;
	// }
}
</style>

