import MarketingSceneService from '../services/MarketingSceneService'
import UserService from '../services/UserService'
import ConfigUtil from '../common/Config.js'
import uni from '../services/UNIService'
export default {
	data() {
		return {
			rootTradingAreaId: ConfigUtil.getRootTradingAreaId(),
			shareUserId: null, //分享人的userId
			qrCodeId: null,
			relationId: null,
			currentUser: {}, //被邀请的人
		}
	},
	watch: {},
	methods: {
        async joinInit(options){
            this.qrCodeId = await decodeURIComponent(options.scene) != 'undefined' ? decodeURIComponent(options.scene) : null;
            var captainUserId = options.captainUserId;
            var marketingSceneId = options.marketingSceneId;

			if(this.qrCodeId) {
                var qrcodeRes = await UserService.doGetQRCodeById(this.qrCodeId);
                var qrcode = qrcodeRes.data;

				if(qrcode) {
					var extrParameter = JSON.parse(qrcode.extrParameter);
					if(!marketingSceneId) {
						marketingSceneId = extrParameter.marketingSceneId;
					}
					if(!captainUserId) {
						captainUserId = extrParameter.captainUserId;
					}
				}

            }

            if(captainUserId) {
                uni.setStorageSync("captainUserId", captainUserId);
                MarketingSceneService.doJoin(captainUserId, uni.getStorageSync("userId"), ConfigUtil.getRootTradingAreaId());
            }

            return marketingSceneId;
		}
	}
}
