<template>
    <div class="default-wrap">
    
         <div class="body-content-wrap main-mall-wrap">

                <div class="mall-banner-box" :style="{  backgroundImage:'url(https://splatformnew.oss-cn-shanghai.aliyuncs.com/zhiguoguo/mall_banner.png)',backgroundPosition: 'center center',backgroundRepeat:'no-repeat',backgroundSize:'cover' }">     
                </div>

                <div class="content-wrap clearfix products-box">

                    <div class="product-category left">
                        <el-radio-group v-model="categoryId" size="small" @change="categoryChange">
                            <el-radio :label="item.value" border v-for="(item,index) in navList" :key="'category'+index">{{item.name}}</el-radio>
                        </el-radio-group>
                    </div>

                    <div class="product-list right">

                        <div class="product-list-search clearfix">
                            <!-- 综合 -->
                            <div class="search-select">
                                <el-dropdown @command="handleCommand($event,1)">
                                    <span class="el-dropdown-link" :class="{ active: value1 }">
                                        {{command1}}<i class="el-icon-arrow-down el-icon--right"></i>
                                    </span>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item :command="index" v-for="(item,index) in options1" :key="'option1'+index" :class="{ active: value1==item.value }">{{item.label}}</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </div>

                            <!-- 创新劵 -->
                            <div class="search-select select2">
                                <el-dropdown @command="handleCommand($event,2)">
                                    <span class="el-dropdown-link " :class="{ active: value2 }">
                                        {{command2}}<i class="el-icon-arrow-down el-icon--right"></i>
                                    </span>
                                    <el-dropdown-menu slot="dropdown" >
                                       <el-dropdown-item :command="index" v-for="(item,index) in options2" :key="'option2'+index" :class="{ active: value2==item.value }">{{item.label}}</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </div>
                            
                            <!-- 筛选 -->
                            <div class="search-select">
                                <el-dropdown  @command="handleCommand($event,3)">
                                    <span class="el-dropdown-link" :class="{ active: value3 }">
                                        {{categoryName}}<i class="el-icon-arrow-down el-icon--right"></i>
                                    </span>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item :command="index" v-for="(item,index) in options3" :key="'options3'+index" :class="{ active: value3==item.id }">{{item.name}}</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </div>

                            <div class="search-form-input">
                                <el-input
                                    size="small"
                                    class="search-input"
                                    placeholder="请输入关键搜索"
                                    v-model="keyword"
                                    @input="change">
                                    <i slot="prefix" class="el-input__icon el-icon-search"></i>
                                </el-input>
                            </div>
                        </div>
                        
                        <su-goods-list :searchModel='searchModel' :isList='true' ref="suGoodsList"></su-goods-list>
                    </div>

                </div>
         </div>
    </div>
</template>

<script>

import suGoodsList from '@/components/su-goods-list/su-goods-list.vue'

import GoodsCategroyService from '@/services/GoodsCategroyService.js'
import TatisticsRankingService from '@/services/TatisticsRankingService.js'
import ConfigUtil from '@/common/Config';
export default{
    components:{
     
        suGoodsList
    },
    data(){
        // 因为直接ES5已经舍弃了Object.observe方法，Vue无法监听对象属性删除和新增，故即使使用deep方法监听对象prop也没有用。
        // 使用Object.assign({}, obj)方法
        let searchModel=Object.assign({}, {
                tradingAreaId: ConfigUtil.getBusinessTradingAreaId(),
                discountStatus:null,
                reviewStatus:1,
                sellStatus:1,
                sortExp:null,
                categoryId:null,
                nameKeyword:''
            });

        return {

            searchModel: searchModel,

            SearchRecordArr: [],
            keyword: '',
            tabbar: [],
            categoryName: "筛选",

            categoryId:'212001000000000000',
            navList: [{
                id: 1,
                name: '人工智能',
                value: '212001000000000000',
                img: 'https://splatformnew.oss-cn-shanghai.aliyuncs.com/zhiguoguo/home_tab_1.png'
            }, {
                id: 2,
                value: '212002000000000000',
                name: '激光光电',
                img: 'https://splatformnew.oss-cn-shanghai.aliyuncs.com/zhiguoguo/home_tab_2.png'
            }, {
                id: 3,
                name: '生物医药',
                value: '212003000000000000',
                img: 'https://splatformnew.oss-cn-shanghai.aliyuncs.com/zhiguoguo/home_tab_3.png'
            }, {
                id: 4,
                value: '212004000000000000',
                name: '智能制造',
                img: 'https://splatformnew.oss-cn-shanghai.aliyuncs.com/zhiguoguo/home_tab_4.png'
            }, {
                id: 5,
                value: '212007000000000000',
                name: '节能环保',
                img: 'https://splatformnew.oss-cn-shanghai.aliyuncs.com/zhiguoguo/jienenghuanbao.png'
            }, {
                id: 6,
                name: '检验检测',
                value: '212006000000000000',
                img: 'https://splatformnew.oss-cn-shanghai.aliyuncs.com/zhiguoguo/jianyanjiance.png'
            },{
                id: 7,
                name: '服务券',
                value: '212008000000000000',
                img: 'https://oss.suconnect.com/file/b064cb469ae011ec8d76d77370cb8f99.png@'
            }],
            value1: 0,
            value2:0,
            value3:0,
            cur: {},
            
            options1: [{
                    label: '最新上架',
                    value: 1
                },
                {
                    label: '热销推荐',
                    value: 2
                },
                {
                    label: '价格由高到低',
                    value: 5,
                },
                {
                    label: '价格由低到高',
                    value: 6,
                }
            ],
            options2: [{
                    label: '创新券服务',
                    value: 3,
                },
                {
                    label: '非创新券服务',
                    value: 4,
                }
            ],
            options3:[],

            command1:'综合',
            command2:'创新劵',

        }
    },
    computed:{
    
    },
    
    created(){
            if(this.$route.params.keyword) {
				// console.log(this.$route.params.keyword)
				this.keyword=this.$route.params.keyword;
                this.change(this.keyword);
			}
            
            if(this.$route.params.categoryId) {
				// console.log(this.$route.params)
				this.categoryId=this.$route.params.categoryId;
			}
            // console.log(this.$route.params)
            if(this.$route.params.type) {
                let type=parseInt(this.$route.params.type);

                if(type==3){
                    this.searchModel.sortExp = "complexValue desc";
                    this.value1=2;
                    this.command1='热销推荐';
                }
             
				switch (type) {
                    case 3:
                    case 4:
                        let opt2=this.options2.find(item => {
                            //定义一个index,index接收我们找到的全部数据
                            return item.value == type
                            //当我们里面循环的值和我们要找的值相等的时候返回
                        })
                        this.command2=opt2['label'];
                        this.value2=type;
                        this.dropdownChange(2,type);
                        break;
                    case 1:
                    case 2:
                    case 5:
                    case 6:
                        let opt1=this.options1.find(item => {
                            //定义一个index,index接收我们找到的全部数据
                            return item.value == type
                            //当我们里面循环的值和我们要找的值相等的时候返回
                        })
                        this.command1=opt1['label'];
                        this.value1=type;
                        this.dropdownChange(1,type);
                        break;
                    
                }
				
			}
			this.doSearchTreeByGroup();
   
    },

    mounted(){

        this.searchModel.categoryId= this.categoryId;
        
        this.init();
    },
    methods:{

        init(){
            this.$refs.suGoodsList.doPage();
        },

        //条件筛选
        handleCommand(e,type){
            // console.log(e,type)
            if(type==1){
                this.command1=this.options1[e]['label'];
                this.value1=this.options1[e]['value'];
                this.dropdownChange(this.options1[e]['value']);
            }else if(type==2){
                this.command2=this.options2[e]['label'];
                this.value2=this.options2[e]['value'];
                this.dropdownChange(this.options2[e]['value']);
            }else if(type==3){
                this.categoryName=this.options3[e]['name'];
                this.value3=this.options3[e]['id'];
                // console.log(this.options3[e]);
                this.toSearch(this.options3[e])
            }
            // console.log(this.searchModel);

            this.$refs.suGoodsList.setPage();
            this.$refs.suGoodsList.doPage();
            
        },
        // 分类切换
        categoryChange(){
            let category=this.tabbar.find(item => {
                //定义一个index,index接收我们找到的全部数据
                return item.id == this.categoryId
                //当我们里面循环的值和我们要找的值相等的时候返回
            })

            if(category!=[]){
                this.options3=category.nodes;
                this.categoryName='筛选';
                this.cur= {};
                this.value3=0;
                this.searchModel.categoryId= this.categoryId;
                this.$forceUpdate();
                // console.log(this.searchModel);
                this.$refs.suGoodsList.setPage();
                this.$refs.suGoodsList.doPage();
            }
            
        },

        

        toSearch(e) {
            this.categoryName = e.name;
            this.cur.id = e.id
            // console.log(this.cur.id)
            this.searchModel.categoryId= e.id
            // this.$refs.uDropdown.close();
             this.$forceUpdate();
        },
        dropdownChange(e) {
            // console.log(e);
            switch (e) {
                case 1:
                    this.searchModel.sortExp = "createDate desc"
                    break;
                case 2:
                    this.searchModel.sortExp = "complexValue desc"
                    break;
                case 3:
                    this.searchModel.discountStatus = 1
                    break;
                case 4:
                    this.searchModel.discountStatus = 0
                    break;
                case 5:
                    this.searchModel.sortExp = "presentPrice desc"
                    break;
                case 6:
                    this.searchModel.sortExp = "presentPrice asc"
                    break;
            }
        },
        async doSearchTreeByGroup() {
            var res = await GoodsCategroyService.doSearchTreeByGroup();
            if (res.status == 1) {
                // res.data.forEach((items,index)=>{
                // 	var nodesList =items.nodes
                // 	items.nodes=nodesList.concat(nodesList)
                // 	console.log(items.nodes)
                // })
                this.tabbar = res.data;

                if(res.data){
                    let category=res.data.find(item => {
                        //定义一个index,index接收我们找到的全部数据
                        return item.id == this.categoryId
                        //当我们里面循环的值和我们要找的值相等的时候返回
                    })
                    this.options3=category.nodes;
                }

            }
        },
        //获取字符长度
        GetLength(str) {
            var realLength = 0, len = str.length, charCode = -1;
            for (var i = 0; i < len; i++) {
                charCode = str.charCodeAt(i);
                if (charCode >= 0 && charCode <= 128){
                    realLength += 1;
                } else{
                    realLength += 1;
                }  
            }
            return realLength;
        },
        // 搜索输入框查询事件
        change(keyword) {
            if (!keyword) {
                this.searchModel.nameKeyword='';
                // this.searchModel.categoryId= this.categoryId;
                return;
            }
            // 搜索框内容变化时，会触发此事件，keyword值为输入框的内容
            // console.log('44444',keyword);
            // this.searchModel.nameKeyword=keyword;
            this.searchModel = {
                tradingAreaId: ConfigUtil.getBusinessTradingAreaId(),
                sellStatus: 1,
                nameKeyword: keyword,
                // discountStatus:null,
                reviewStatus:1,
                sortExp:this.searchModel.sortExp,
                categoryId:null,
            }
            // 关键词搜索时去除分类条件
            this.cur= {};
            this.categoryId='';
            this.categoryName='筛选';
            this.value3=0;

        //    console.log(this.GetLength(keyword));
            let keywordLength=this.GetLength(keyword)
            if(keywordLength>=2){
                let KeywordRanking = 'KeywordRanking_'+ConfigUtil.getBusinessTradingAreaId()
                let KeywordDistribution = 'KeywordDistribution_'+ConfigUtil.getBusinessTradingAreaId()
                this.doAdd(KeywordRanking,this.keyword,1)
                this.doAdd(KeywordDistribution,'网站',1)
            }
           
           
        },
        showPop() {
            this.show = true
        },
        // 关键词排行统计
        async doAdd(key,value,score) {
            var res = await TatisticsRankingService.doAdd(key,value,score);
            if (res.status == 1) {
            
            }
        },
        search(keyword) {
            if (!this.keyword) {
                uni.showToast({
                    title: '请输入搜索内容',
                    icon: 'none',
                })
                return;
            }
            this.searchModel = {
                tradingAreaId: ConfigUtil.getBusinessTradingAreaId(),
                sellStatus: 1,
                nameKeyword: keyword
            }

            

        },
        /**
         * 记录点击
         */
        onRecord(val) {
            // uni.navigateTo({
            //     url: '/pages/SearchGoodsList/SearchGoodsList?keyword=' + encodeURIComponent(val),
            // })
        }
		


    }
}
</script>

<style lang="scss" scoped>
    .main-mall-wrap{
        min-width: 1100px;
        position: relative;
        margin-top: 68px;

        .mall-banner-box{
            width:100%;
            height: 430px;
            position: relative;
        
            img{
                width:100%;
            }
        }

        .products-box{
            margin-top:50px;

            .product-category{
                width:180px;
                // margin-right: 15px;
                text-align: left;
                
                .el-radio-group{
                    /deep/.el-radio__input{
                        display:none;
                    }
                    
                    .el-radio{
                        display: block;
                        margin:0 0 20px;
                        height: 42px;
                        line-height:42px;
                        padding:0 15px;
                        width: 140px;
                        text-align: center;
                        border:none;
                        border-radius:5px;
                    }

                    .el-radio.is-checked{
                        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

                        /deep/.el-radio__label{
                            font-weight: 600;
                        }
                    }

                    .el-radio.is-bordered+.el-radio.is-bordered{
                        margin-left: 0;
                    }

                    /deep/.el-radio__label{
                        font-size:20px;
                        padding-left: 0;
                    }
                }
            }

            .product-list{

                width:calc(100% - 180px);

                .product-list-search{
                    height: 50px;
                    line-height: 50px;
                    text-align: left;
                    display: flex;
                    padding:0 15px;

                    .search-select{
                        min-width:140px;
                        
                        .el-dropdown-link{
                            font-size: 18px;
                        }
                        .active{
                            color:#409EFF;
                            font-weight: 600;
                        }
                    }

                    .select2{
                        margin-right: 15px;
                    }
                    .search-form-input{
                        width: calc(100% - 435px);
                        text-align: right;
                        .search-input{
                            width:275px;
                            text-align: left;

                            /deep/.el-input__inner{
                                border-radius: 32px;
                            }
                        }
                    }
                    
                }
            
                
            }
        }

        


    }
</style>

<style>
.el-dropdown-menu__item.active{
    color:#409EFF;
}
</style>
