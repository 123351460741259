<template>
    <div class="default-wrap">
        
        <div class="body-content-wrap main-pdt-detail-wrap">

             <div class="pdt-detail-banner-box" :style="{  backgroundImage:'url(https://splatformnew.oss-cn-shanghai.aliyuncs.com/zhiguoguo/pdt_detail_banner.png)',backgroundPosition: 'center center',backgroundRepeat:'no-repeat',backgroundSize:'cover' }" >
                    
            </div>

            <div class="pdt-info-box clearfix">
                <div class="pdt-info-left left">
                    <el-carousel trigger="click" height="330px">
                        <el-carousel-item v-for="(item,index) in goods.showUrlList" :key="index">
                             <el-image
                                style="width: 330px; height: 330px"
                                :src="item.url"
                                v-if="item.type=='img'"
                                fit="cover">
                              </el-image>
                              <div v-if="item.type=='video'">
                                <pdt-video  :videoUrl="item.url"></pdt-video>
                              </div> 
                        </el-carousel-item>
                    </el-carousel>

                    <img :src="require('../../assets/img/coupon_icon.png')" v-if="goods.discountStatus" class="product-coupon" alt="创新优惠券">
                </div>

                <div class="pdt-info-right right">
                    <div class="product-title">
                        {{goods.title}}
                    </div>
                    <div class="pdt-info-other">
                       
                    </div>
                    <div class="pdt-nums-content">
                        <span class="tit">数量:</span> 
                        <span class="pdt-nums">
                            <el-button class="btn-minus" :disabled="goods.cartCount<=1" size="mini" @click="goods.cartCount--"> - </el-button>
                            <el-input v-model="goods.cartCount" placeholder="数量"  class="input-nums" size="mini"></el-input>
                            <el-button class="btn-plus" size="mini" @click="goods.cartCount++"> + </el-button>
                        </span>
                    </div>
                    <div class="pdt-price-content" v-if="goods.presentPrice">
                        <span class="tit">价格:</span> <span> <span class="price-symbol">¥</span> <span class="pdt-price">{{goods.presentPrice}}</span> <span class="price-unit">{{goods.unitName}}</span> </span>
                        <img :src="require('../../assets/img/coupon_icon.png')" width="78px" class="product-coupon" height="25px" alt="创新优惠券" v-if="goods.discountStatus">
                    </div>
                    <div class="pdt-price-content" v-if="goods.presentPrice==0">
                        按需求报价
                    </div>

                    <div class="pdt-btn-tool clearfix">
                        <el-button  class="reserve-btn" :disabled="goButtonDisabled" @click.stop="doBuying">{{goButtonLabel}}</el-button>
                        <el-button class="advisory-btn" @click="callPhone">我要咨询</el-button>
                    </div>
                    
                </div>   
            </div>
            <!-- 服务流程 -->
            <div class="service-process-box">
                <div class="common-title">
                    <div class="title-top">
                        创新劵服务流程
                    </div>
                    <h3>SERVICE PROCESS</h3>
                </div>
                <div class="sevice-process-cont">
                    <img :src="require('../../assets/img/service_process_img.png')" alt="创新劵服务流程">
                </div>
            </div>
            <!-- 商品详情 -->
            <div class="pdt-detail-box">
                <div class="common-title">
                    <div class="title-top">
                        详情介绍
                    </div>
                    <h3>DETAILS</h3>
                </div>

                <div class="pdt-detail-cont">
                    <div v-html="goods.goodsDesc" v-if="goods.goodsDesc"></div>
                    <div v-else>暂无更多详细介绍</div>
                </div>
            </div>
            <!-- 排行榜 -->
            <div class="pdt-recommend-box">
                <div class="common-title">
                    <div class="title-top">
                        排行榜
                    </div>
                    <h3>LEADERBOARD</h3>
                </div>

                <su-goods-box :searchModel='rankSearchModel' :count="4" :marginlr="'0px'"></su-goods-box>

            </div>
        </div>

        <!-- 下单确认弹框 -->
        <confirm-order ref="confirmOrder"></confirm-order>
    </div>
            
</template>


<script>

import suGoodsBox from '@/components/su-goods-box/su-goods-box.vue'
import confirmOrder from '@/components/order/confirmOrder.vue'
import pdtVideo from '@/components/others/pdt-video.vue'

import UserService from '../../services/UserService'
import GoodsService from '../../services/GoodsService'
import CommonUtil from "../../common/Common"
import GroupService from '../../services/GroupService'
import CartService from '../../services/CartService'
import GoodsShopCategroyService from "../../services/GoodsShopCategroyService";
import TatisticsRankingService from '@/services/TatisticsRankingService.js'
import JionRelation from '../../mixins/JoinRelation';
import Config from "../../common/Config";
import uni from "../../services/UNIService";


export default {
    components: {
      
        suGoodsBox,
        confirmOrder,
        pdtVideo
    },
    mixins:[JionRelation],
    data() {
        return {
          
            TabShow: 0,
            isMore: false,
            thatBang:false,
            AttentionShow: 0,
            PageScrollTop: 0,
            type: 0,
            config: {
                title: '商品详情',
            },
            goods: {
                presentPrice: "-",
                originalPrice: "-",
                couponPrice: "-",
                marketPrice:"-",
                showUrlList:[]
            },
            po: 1,
            group: null,
            goButtonDisabled: true,
            goButtonLabel: "立即选购",
            isCallGoods:false,
            rankSearchModel:{}
        }

    },

    async created(){
        // this.init()

        // if(this.$route.params.id) {
        //     console.log(this.$route.params)
        //     // this.categoryId=this.$route.params.id;
        // }
        
    },
    watch:{
        $route: {
          immediate: true, // 一旦监听到路由的变化立即执行
          handler(to, from) {
            // console.log("监听路由：" , to);
            // console.log("监听路由：" , from);
            this.init();
          }
        }
    },
    // async onShow() {
    //   let _this = this;
    //   _this.$refs.Sku.hideSku();
    //   if(this.goods.cartId) {
    //     var cart = await CartService.doSetCount(null, 1, this.goods);
    //     this.goods.cartCount = cart.count;
    //     this.goods.cartId = cart.id;
    //   }
    // },
    // onPageScroll(e) {
    //   this.PageScrollTop = e.scrollTop;
    // },
    // onShareAppMessage (res){ //该段代码只能放在page中，不能放到组件
    //   return {
    //     title: this.goods.title,
    //     imageUrl:  this.goods.showUrlList.length>0?this.goods.showUrlList[0]:'https://splatformnew.oss-cn-shanghai.aliyuncs.com/babuluo/default_bg.png',
    //     path: '/pages/GoodsDetails/GoodsDetails?goodsId=' + this.goods.id +'&captainUserId=' + uni.getStorageSync("userId"),
    //     success: (successRes) => {
    //       console.log('转发成功')
    //     },
    //     fail: (res) => {
    //       console.log('转发失败')
    //     }
    //   }
    // },
    methods: {

      async init(){
        let option={};
        if(this.$route.query){
            option=this.$route.query;
        }

        // await this.checkUserAndGetSessionKey();

        await this.joinInit(option);

        if(option.captainUserId) {
            if(!this.userAuth) {
                this.$refs["login"].open();
            }
        }
        this.goods.id = option.goodsId;

        var goodsRes = await GoodsService.doGet(this.goods.id);
        if (goodsRes.status != 1 || !goodsRes.data) {
            return;
        }
        var goods = goodsRes.data;
        // console.log(goods);


        //
        if(goodsRes.data.videoShowUrl) {
            let videosArr=goodsRes.data.videoShowUrl.split(',');
            let showUrlList=[];
            if(videosArr.length>0){
              for(let i=0;i<videosArr.length;i++){
                showUrlList.push({
                    type:"video",
                    url:videosArr[i]
                })
              }
            }
            goods.showUrlList=showUrlList;
        }else{
            goods.showUrlList = [
              {
                type:'img',
                url:"https://splatformnew.oss-cn-shanghai.aliyuncs.com/zhiguoguo/goods_default.png"
              }
            ]
        }

        // 处理图片
        if(goodsRes.data.showUrl) {
            goodsRes.data.showUrl = CommonUtil.replaceAll(goodsRes.data.showUrl, "/(.*)xx_l/", "$1x1_l");
            let imgsArr=goodsRes.data.showUrl.split(',');
        
            if(imgsArr.length>0){
              for(let i=0;i<imgsArr.length;i++){
                goods.showUrlList.push({
                    type:"img",
                    url:imgsArr[i]
                })
              }
            }
        }
        
      

        if(goods.skuList && goods.skuList.length > 0) {
            if(goods.goodsSkuImgList && goods.goodsSkuImgList.length>0) {
                goods.goodsSkuImgList.forEach(function (skuImg) {
                    if(skuImg.skuId == goods.skuList[0].id) {
                        goods.showUrlList = skuImg.showUrl.split(',');
                    }
                    goods.skuList.forEach(function (skuItem) {
                        if(skuImg.id == skuItem.id) {
                        skuItem.showUrlList = skuImg.showUrl.split(',');
                        }
                    })
                })
            }

            //设置自定义sku名称
            if(goods.goodsCustomerLabelList && goods.goodsCustomerLabelList.length>0) {
                goods.skuPropList.forEach(function (skuProp) {
                    skuProp.valueList.forEach(function (skuValue) {
                        goods.goodsCustomerLabelList.forEach(function (customerLabel) {
                        if(skuValue.id == customerLabel.valueId) {
                            skuValue.value = customerLabel.label;
                        }
                        });
                    });
                })
            }

            //设置SKU用券金额
            goods.skuList.forEach(function (skuItem) {
                skuItem.couponPrice = CommonUtil.toDecimal2(skuItem.originalPrice - skuItem.presentPrice);
            });
        }

        const regex = new RegExp('<img', 'gi');
        const regexTable = new RegExp('<table', 'gi');
        if(goods.goodsDesc) {
            goods.goodsDesc = goods.goodsDesc.replace(regex, `<img style="width: 100%;display:block;"`);
            goods.goodsDesc = goods.goodsDesc.replace(regexTable, `<table style="width: 100%;border:1rpx solid grey" border="0" cellspacing="1" cellpadding="0"`);
        }

        var cart = await CartService.doSetCount(null, 1, goods);
        goods.cartCount = cart.count;
        goods.cartId = cart.id;

        this.goods = goods;

        //关键词排行
        let goodsFlow="GoodsFlow_"+Config.getBusinessTradingAreaId()
        this.doAdd(goodsFlow,goods.title,1)

        // console.log('商品标题',goods.title);

        this.goButtonDisabled = false;

        // uni.hideLoading();

        if (this.goods.quantity == 0) {
            this.goButtonDisabled = true;
            this.goButtonLabel = "已售完";
        }

        if (this.goods.sellStatus == 0) {
            this.goButtonDisabled = true;
            this.goButtonLabel = "已下架";
        }

        this.rankSearchModel = {
            tradingAreaId: Config.getBusinessTradingAreaId(),
            sellStatus: 1,
            categoryId:this.goods.categoryId,
            sortExp: "complexValue desc"
        };

        this.group = await GroupService.doGetOneGroup(this.goods.groupId);
      },
      // 关键词排行
      async doAdd(key,value,score) {
          var res = await TatisticsRankingService.doAdd(key,value,score);
          if (res.status == 1) {
          
          }
      },


      doBuying() {
        if (UserService.checkUserAuth()) {
          // this.$confirm('系统优化升级中，商拼下单功能暂时关闭，敬请期待！', '提示', {
          //   confirmButtonText: '确定',
          //   cancelButtonText: '取消',
          //   type: 'warning'
          // }).then(() => {
          //   console.log('确定')
          // }).catch(() => {
          //   console.log('取消')      
          // });
          // return;

          var cartGoodss = [this.goods];
          uni.setStorageSync("goodss_buy", JSON.stringify(cartGoodss));
          this.$refs.confirmOrder.add({groupId:this.goods.groupId})
        }else{
            this.$message({
              message: '请先登录',
              type: 'warning'
            });
            setTimeout(()=>{
                this.$router.push({
                  path:'/login'
                })
            },1500)
        }
          
          // uni.navigateTo({
          //   url: "/pages/Order/ConfirmOrder?groupId=" + this.goods.groupId
          // })
      },

      previewImage(url) {
        uni.previewImage({
          current: url, // 当前显示图片的http链接
          urls: this.goods.showUrlList // 需要预览的图片http链接列表
        })
      },
      doBuy() {
        this.$refs["Sku"].showSku();
        // this.$invoke('Sku','showSku');
      },
      async callPhone() {

          
          if (this.group.phone == null) {
            this.group = await GroupService.doGetOneGroup(this.goods.groupId);
          }
          console.log(this.group.contact);
          this.$confirm(this.group.contact + '', '联系电话', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            
          }).catch(() => {
                    
          });
      },
      //点击切换
      clickTab(e){
        if (this.currentTab == e.target.dataset.current) {
          return false;
        } else {
          this.currentTab = e.target.dataset.current;
        };
      },
      toCard() {
        var url = "/pages/order/order_ticket?goodsTitle=大礼包"
        uni.navigateTo({
          url: url
        });
      },
      doCall() {
        uni.makePhoneCall({
          phoneNumber: '18951958089'
        });
      },
      toShare() {
        this.$refs['sharebox'].open();
      },
      async loginCallback(res, loginUser) {
        uni.showToast({
          title: "登录成功，祝您使用愉快！",
          icon: 'none',
          duration: 1500
        });
        await this.checkUserAuth();
      },
      /**
       * 返回
       */
      onBack() {
        uni.navigateBack();
      },
      /**
       * tab
       */
      onTab(type) {
        this.TabShow = type;
        switch (type) {
          case 0:
            uni.pageScrollTo({
              scrollTop: 0,
              duration: 300
            });
            break;
          case 1:
            uni.createSelectorQuery().select(".evaluate-data").boundingClientRect((data) => { //data - 各种参数
              uni.pageScrollTo({
                scrollTop: this.PageScrollTop + data.top -50,
                duration: 300
              });
            }).exec()
            break;
          case 2:
            uni.createSelectorQuery().select(".products-introduction").boundingClientRect((data) => { //data - 各种参数
              uni.pageScrollTo({
                scrollTop: this.PageScrollTop + data.top - 50,
                duration: 300
              });
            }).exec()
            break;
        }
      },
      /**
       * 去购物车
       */
      onToCart() {
        uni.switchTab({
          url: '/pages/cart/cart'
        })
      },
      /**
       * 降价通知点击
       */
      onDepreciate() {
        uni.showToast({
          title: '降价通知提醒成功',
          icon: 'success'
        })
      },
      /**
       * 关注点击
       */
      onAttention() {
        if (this.AttentionShow === 0) {
          this.AttentionShow = 1;
          uni.showToast({
            title: '关注成功',
            icon: 'none'
          })
        } else {
          this.AttentionShow = 0;
          uni.showToast({
            title: '取消成功',
            icon: 'none'
          })
        }
      },
      toHome() {
        uni.switchTab({
          url: '/pages/Home/Home'
        });
      }
    }
}
</script>


<style lang="scss" scoped>
.main-pdt-detail-wrap{
    min-width: 1100px;
    position: relative;
    width: 100%;
    margin-top: 68px;

    .pdt-detail-banner-box{ 
        width:100%;
        height: 430px;
        position: relative;  
    }

    .pdt-info-box{
        position: relative;
        margin:60px auto 0;
        width:900px;
        

        .pdt-info-left{
            width:330px;
            height:330px;
            border-radius: 10px;
            overflow: hidden;
            position: relative;

            /deep/.el-carousel{
              img{
                width:100%;
              }
              .el-carousel__item{
                background: #efefef;
              }
              .video-js{
                height: 330px;
              }

              .el-carousel__indicators{
                display: none;
              }

            }
           

            .product-coupon{
                 position: absolute;
                top:15px;
                right:15px;
                z-index: 20;
                width:105px;
                height:34px;
            }


        }

        .pdt-info-right{
            margin-left:25px;
            width:calc(100% - 355px);
            height:330px;
            text-align: left;

            .product-title{
                font-size:36px;
                height: 46px;
                line-height: 46px;
                font-weight: 600;
                margin-bottom: 30px;
            }

            .tit{
                color:#333;
                font-weight: 600;
            }
            .pdt-info-other{
                height:68px;
                // min-height: 68px;
            }
            .product-other{
                height:70px;
            }
            .product-manufacturer,.product-pattern,.product-type,.product-address{
    
                width:48%;
                font-size: 18px;
                color:#666;
                vertical-align: top;
                margin-bottom: 10px;

                span{
                    color:#333;
                    font-weight: 600;
                }
            }

            .pdt-nums-content{
                margin-top: 20px;
                height: 40px;
                line-height: 40px;
                .tit{
                    // line-height:36px;
                    vertical-align: middle;
                }
                .pdt-nums{
                    margin-left: 10px;

                    /deep/.btn-minus.el-button--mini,.btn-plus.el-button--mini{
                      vertical-align: middle;
                      padding:6px 0;
                      width: 28px;
                      text-align: center;
                    }
                    .input-nums{
                       width: 80px;
                       margin:-2px 5px 0;
                       vertical-align: middle;
                    }
                    
                }
            }

            .pdt-price-content{

                height:40px;
                margin-top: 10px;
                margin-bottom: 24px;
               

                .price-symbol{
                    font-weight:600;
                    color:#e61414;
                }
                .pdt-price{
                    font-size: 28px;
                    color:#e61414;
                    font-weight:600;
                }

                .price-unit{
                     font-weight:600;
                }

                .product-coupon{
                    vertical-align: bottom;
                    margin-left: 25px;
                }
            }

            .pdt-btn-tool{

                height:50px;

                .el-button{
                    width:196px;
                    height:50px;
                    font-size: 22px;
                    color:#fff;
                }
                .reserve-btn{
                    background:#2b87fe ;
                }
                .advisory-btn{
                  margin-left:30px;
                    background: #e61414;
                }
            }
        }

    }

    .service-process-box{
        margin-top: 60px;
        padding:40px;

        .sevice-process-cont{
            width: 900px;
            height: 215px;
            margin:40px auto 0;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

            img{
                width: 900px;
                height: 215px;
            }
        }
    }

    .pdt-detail-box{
        padding-bottom: 20px;
        .title-top::before{
             margin-left: -120px;
        }
        .title-top::after{
            margin-left: 95px;
        }

        .pdt-detail-cont{
            margin:0 auto;
            width:900px;
            min-height: 100px;
            text-align: left;
            line-height: 34px;  
        }
    }


    .pdt-recommend-box{
        .title-top::before{
             margin-left: -120px;
        }
        .title-top::after{
            margin-left: 95px;
        }

        
    }
}
</style>