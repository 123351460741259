<template>
    <div class="default-wrap">
        <div class="body-content-wrap main-contact-wrap">

            <div class="contact-banner-box" :style="{  backgroundImage:'url(https://splatformnew.oss-cn-shanghai.aliyuncs.com/zhiguoguo/contact_banner.png)',backgroundPosition: 'center center',backgroundRepeat:'no-repeat',backgroundSize:'cover' }">
                    <!-- <img :src="newsBanner" alt="新闻公告"> -->
            </div>

            <div class="contact-box">
                <div class="common-title">
                    <div class="title-top">
                        联系我们
                    </div>
                    <h3>CONTACT US</h3>
                </div>
                <div class="contact-us">
                    <div class="content-wrap contact-us-list">
                        <div class="contact-item" v-for="(item,index) in contacts" :key="index">
                            <div class="contact-icon">
                                <img :src="item.imgUrl" :alt="item.text">
                            </div>
                            <div class="contact-tit">
                                {{item.title}}
                            </div>
                            <div class="contact-txt">
                                {{item.text}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="location-map-box">
                <div class="common-title">
                    <div class="title-top">
                        地图位置
                    </div>
                    <h3>MAP  LOCATION</h3>
                </div>

                <div class="content-wrap">
                    <div class="map">
                        <img :src="'https://splatformnew.oss-cn-shanghai.aliyuncs.com/zhiguoguo/location_map.png'" alt="江苏省南京市栖霞区兴智路6号兴智科创园">
                    </div>
                    
                </div>
                
            </div>

        </div>
    </div>
</template>

<script>
export default{
    data(){
        return {
            contacts:[
                {
                    imgUrl:require('../../assets/img/contact_location_icon.png'),
                    title:'公司地址',
                    text:'江苏省南京市栖霞区兴智路6号兴智科创园',
                },
                {
                    imgUrl:require('../../assets/img/contact_tel_icon.png'),
                    title:'联系电话',
                    text:'025-85770521',
                },
                {
                    imgUrl:require('../../assets/img/contact_mail_icon.png'),
                    title:'联系邮箱',
                    text:'1457584847@qq.com',
                }

            ]
        }
    },
    components:{
    }
}
</script>

<style lang="scss" scoped>
    .main-contact-wrap{
        min-width: 1100px;
        position: relative;
        margin-top: 68px;

        .contact-banner-box{
            width:100%;
            height: 430px;
            position: relative;
        
            img{
                width:100%;
            }
        }

        .contact-box{

            .common-title{
                margin:30px 0;
                .title-top::before{
                    margin-left: -130px;
                }
                .title-top::after{
                    margin-left: 105px;
                }
            }


            .contact-us{

                width:100%;
                height:414px;
                background: #1a9cfd;
                padding-top: 115px;

                .contact-us-list{
                    padding:0 80px;
                    display: flex;
                    justify-content: space-between;
                    

                    .contact-item{
                        width:265px;
                        height:225px;
                        padding:0 30px;
                        position: relative;
                        background: #fff;
                        border-radius: 10px;
                        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

                        .contact-icon{
                            width:98px;
                            height: 98px;
                            border-radius: 98px;
                            position: relative;
                            top:-49px;
                            margin:0 auto;
                            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
                            background: #fff;

                            img{
                                margin: 25px auto 0;
                                width: 48px;
                                height:48px;
                            }
                        }

                        .contact-tit{
                            margin-top: -20px;
                            height:40px;
                            line-height: 24px;
                            font-size: 24px;
                            font-weight: bold;
                        }

                        .contact-txt{
                            line-height: 26px;
                            font-size: 16px;
                            font-weight: 600;
                        }
                    }
                }

            }
        }

        .location-map-box{
            position: relative;
            
            .common-title{
                margin:30px 0;
                .title-top::before{
                    margin-left: -130px;
                }
                .title-top::after{
                    margin-left: 105px;
                }
            }

            .content-wrap{
                margin-bottom: 60px;

                .map{
                    margin:0 auto;
                    width: 900px;
                    height: 515px;
                    border-radius: 15px;
                    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
                    overflow: hidden;
                    img{
                   
                        width: 900px;
                        height: 515px;
                    }
                }

                
            }
        }

    }
</style>