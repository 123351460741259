<template>
    <div>
        <video
            :src="videoUrl"
            :controls="videoOptions.controls"
            class="video-js vjs-big-play-centered vjs-fluid"
            webkit-playsinline="true"
            playsinline="true"
            x-webkit-airplay="allow"
            x5-playsinline
            style="height:330px;width:100%;"
            @play="onPlayerPlay"
            @pause="onPlayerPause"
            autoplay="false"
            ref="video">
                <!-- <source :src="videoUrl" type="video/mp4"> -->
        </video>
    </div>
</template>

<script>
export default {
        name: "showVideo",
        props:['videoUrl'],
        data() {
            return {
                videoOptions: {
                    controls:true,
                    src:'https://splatformnew.oss-cn-shanghai.aliyuncs.com/file/0ad3138daede11eba753fdadd02728ea.mp4', // url地址
                },
                player: null,
                playTime:'',
                seekTime:'',
                current:'',
            }
        },
        // computed:{
        //     videoUrl2:function(){
        //         this.videoOptions.src=this.videoUrl;
        //     }
        // },
        watch: {
            videoUrl: function (val, oldVal) {
                this.videoOptions.src=videoUrl
            },
        },
        mounted() {
            this.initVideo();
        },
        methods: {
                initVideo() {
                //原生初始化视频方法
                let myVideo = this.$refs.video;
                //ontimeupdate
                  myVideo.ontimeupdate = function() {myFunction()};
                  let _this = this;

                  function myFunction(){
                      let playTime = myVideo.currentTime
                      setTimeout(function () {
                          localStorage.setItem("cacheTime",playTime)
                      },500)
                      let time = localStorage.getItem("cacheTime")
                      // 当前播放位置发生变化时触发。
                      if(playTime - Number(time) > 2){
                          myVideo.currentTime = Number(time)
                      }else{
                      }
                  };
            },
            // 播放回调
            onPlayerPlay(player) {
                // var video = document.getElementById("video");
                player.controls=false;
                // this.globalSetting = true
                console.log("player play!", player);
                // document.getElementsByClassName("vjs-control-bar").style.display = "block";
                // document.getElementsByClassName("vjs-control-bar").style.display = "block";
            },

            // 暂停回调
            onPlayerPause(player) {
                // this.globalSetting.controls = false;
                // console.log("player pause!", player);
                // var video = document.getElementById("video");
                player.controls=true;
                // document.getElementsByClassName("vjs-control-bar").style.display = "none";
            },
        },

        beforeDestroy() {
            if (this.player) {
                this.player.dispose()
            }
        }
    }
</script>